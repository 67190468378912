import BrowseLayout from '@/layouts/Browse'

export default [
  {
    path: 'browse',
    component: BrowseLayout,
    meta: {
      title: 'Home',
    },
    children: [
      {
        path: '',
        name: 'client-home',
        component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/home'),
        meta: {
          title: 'All Products',
        },
      },
    ],
  },
  {
    path: '/product-details/:id',
    name: 'product-details',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/product-details'),
    meta: {
      title: 'Product Details',
    },
  },
  {
    path: 'cart',
    name: 'shopping-cart',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/shopping-cart'),
    meta: {
      title: 'Shopping Cart',
    },
  },
  {
    path: 'search',
    name: 'client-search',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/search'),
    meta: {
      title: 'Search Results',
    },
  },
  // REQUIRES AUTHENTICATION
  {
    path: 'checkout',
    name: 'account-checkout',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/checkout/regular.vue'),
    meta: {
      requireAuth: true,
      title: 'Checkout',
      roles: ['customer', 'store']
    },
  },
  {
    path: 'checkout/verify',
    name: 'account-checkout-verify',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/checkout/verify'),
    meta: {
      requireAuth: true,
      title: 'Verify Order',
      roles: ['customer', 'store']
    },
  },
  {
    path: 'checkout/verify/bank',
    name: 'account-checkout-verify-bank',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/checkout/verify/bank'),
    meta: {
      requireAuth: true,
      title: 'Verify Bank Order',
      roles: ['customer', 'store']
    },
  },
]
