var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'cui__layout__grayBackground': _vm.settings.isGrayBackground,
}},[_c('a-layout',{class:{
    'cui__layout__contentMaxWidth': true,
    'cui__layout__appMaxWidth': false,
    'cui__layout__grayBackground': _vm.settings.isGrayBackground,
    'cui__layout__squaredBorders': _vm.settings.isSquaredBorders,
    'cui__layout__cardsShadow': _vm.settings.isCardShadow,
    'cui__layout__borderless': _vm.settings.isBorderless,
  }},[(_vm.settings.menuLayoutType === 'left' && !_vm.settings.isMobileView)?_c('cui-menu-left'):_vm._e(),(_vm.settings.isMobileView)?_c('div',[_c('div',{class:_vm.$style.handler,on:{"click":_vm.toggleMobileMenu}},[_c('div',{class:_vm.$style.handlerIcon})]),_c('a-drawer',{attrs:{"closable":false,"visible":_vm.settings.isMobileMenuOpen,"placement":"left","wrapClassName":_vm.$style.mobileMenu},on:{"close":_vm.toggleMobileMenu}},[_c('cui-menu-left')],1)],1):_vm._e(),(_vm.settings.menuLayoutType === 'top' && !_vm.settings.isMobileView)?_c('cui-menu-top'):_vm._e(),_c('a-layout',[_c('a-layout-header',{class:{
        'cui__layout__header': true,
        'cui__layout__fixedHeader': _vm.settings.isTopbarFixed,
        'cui__layout__headerGray': _vm.settings.isGrayTopbar,
      }},[_c('cui-topbar')],1),_c('cui-breadcrumbs'),_c('a-layout-content',{staticStyle:{"height":"'100%'","position":"'relative'"}},[_c('div',{staticClass:"cui__utils__content",staticStyle:{"margin":"0"}},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)]),_c('a-layout-footer',[_c('cui-footer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }