<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            :class="$style.logo"
          >
            The Church Of Pentecost
            <span></span>
          </a>
          <br/>
          <p class="mb-0">
            Copyright © {{ new Date().getFullYear() }} |
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >Privacy Policy</a>
          </p></div>
        <div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
