<template>
  <a-dropdown
    :trigger="['click']"
    placement="bottomLeft"
    @visibleChange="addCount"
  >
    <div
      :class="$style.dropdown"
      class="d-flex flex-column justify-content-center h-100"
    >
      <a-badge>
        <template v-if="isVerified" #count>
          <a-tooltip color="red" :title="whichVerificationIsPending">
            <span
              class="material-symbols-rounded font-size-20"
              style="color: red"
            >
              privacy_tip
            </span>
          </a-tooltip>
        </template>

        <a-avatar
          class="d-flex flex-column justify-content-center"
          shape="circle"
          :class="$style.avatar"
        >
          <template #icon>
            <span
              :class="$style.icon" class="material-symbols-rounded"
              v-if="!getValueFromSource(userData, 'avatar.path')"
            >
              person
            </span>
            <b-avatar v-else ref="previewEl" badge-variant="transparent" :src="getValueFromSource(userData, 'avatar.path')" text="Cristiano Ronaldo"  variant="light-primary" size="4rem">
            </b-avatar>
          </template>
        </a-avatar>
      </a-badge>
    </div>

    <a-menu slot="overlay">
      <a-menu-item key="settings" v-if="userData.user_type === 'store'">
        <router-link :to="{ name: 'store-settings' }">
          <feather-icon icon="SettingsIcon" />
            Settings
          </router-link>
      </a-menu-item>

      <a-menu-item key="profile" v-if="userData.user_type !== 'admin'">
        <router-link :to="{ name: 'account-profile' }">
          <feather-icon icon="UserIcon" />
          {{ $t("topBar.profileMenu.editProfile") }}
        </router-link>
      </a-menu-item>

      <a-menu-item key="security" v-if="userData.user_type === 'customer'">
        <router-link :to="{ name: 'account-security' }">
          <feather-icon icon="LockIcon" />
          {{ $t("topBar.profileMenu.security") }}
        </router-link>
      </a-menu-item>

      <a-menu-item key="orders">
        <router-link :to="{ name: 'account-orders' }">
          <feather-icon icon="ClipboardIcon" />
          {{ $t("topBar.profileMenu.orders") }}
        </router-link>
      </a-menu-item>

      <a-menu-divider />
      <a-menu-item key="logout" @click="logout">
        <feather-icon icon="LogOutIcon" />
        {{ $t("topBar.profileMenu.logout") }}
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    ...mapState('auth', ['userData']),
    loading() {
      return this.$store.state.user.loading
    },
    profileRouteName() {
      const { user_type = 'customer' } = this.userData;
      if (user_type === 'customer') {
        return 'account-profile'
      }

      if (user_type === 'store') {
        return 'store-settings'
      }

      return `${user_type}-profile`
    },
    isVerified() {
      return !(this.getValueFromSource(this.userData, 'meta.email_verified', false) &&
        this.getValueFromSource(this.userData, 'meta.phone_verified', false));
    },
    whichVerificationIsPending () {
      if (!this.getValueFromSource(this.userData, 'meta.email_verified')) return 'Email verification pending'
      else if (!this.getValueFromSource(this.userData, 'meta.phone_verified')) return 'Phone verification pending'
      else return ''
    }
  },
  data: function () {
    return {
      count: 7,
      freelanceData: null
    }
  },
  methods: {
    addCount() {
      this.count++
    },
    logout() {
      this.$store.dispatch('auth/LOGOUT')
    }
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
