<template>
  <div class="d-flex align-items-center justify-content-center mt-5">
    <div class="">
      <transition :name="settings.routerAnimation" mode="out-in">
        <div class="mt-5">
          <div class="d-flex align-items-center align-items-center flex-column mb-3">
            <img width="80" src="@/core/assets/images/logo.png" alt="logo" />
            <h5 class="mt-3 text-center">Welcome to Pentecost Store</h5>
          </div>

          <router-view />

          <div class="d-flex align-items-center align-items-center flex-column mt-5">
            <p class="text-center">
              For further support, you may visit the Help Center or contact our customer service team.
            </p>
            <img width="50" src="@/core/assets/images/logo.png" alt="logo" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthLayout',
  computed: {
    ...mapState(['settings']),
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";

.blur {
  filter: blur(10px);
  transition: all 1s;
}

.noblur {
  transition: all 1s;
}

.left-container {
  background-color: #ffcd00;
  position: relative;
  height: 80vh;
  max-height: 80vh;
}
</style>
