import Vue from 'vue'
import Router from 'vue-router'
import MainLayout from '@/layouts/Main'
import AuthLayout from '@/layouts/Auth'
import ClientLayout from '@/layouts/Client'
import AccountLayout from '@/layouts/Account'
import authRoutes from '@/router/general/routes/auth'
import storeRoutes from '@/router/general/routes/store';
import consumerRoutes from '@/router/general/routes/consumer/public';
import privateConsumerRoutes from '@/router/general/routes/consumer/private';

import { checkLoginStatus, routeGuard } from '@/core/utils/utils';
import { sharedPages, routeMiddleware } from '@/core/utils/router';

Vue.use(Router)

const router = new Router({
  base: process.env.VUE_APP_ROUTE_PREFIX || '',
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      beforeEnter: checkLoginStatus,
      redirect: 'browse',
      component: ClientLayout,
      children: [
        ...consumerRoutes,
        {
          path: 'account',
          component: AccountLayout,
          children: privateConsumerRoutes
        }
      ],
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: authRoutes,
    },
    {
      path: '/store',
      redirect: '/store/dashboard',
      beforeEnter: routeGuard,
      component: MainLayout,
      children: storeRoutes,
    },
  ],
});

(sharedPages || []).forEach(route => {
  router.addRoute(route)
})

router.beforeEach(routeMiddleware);

export default router
