<template>
    <div>
      <div
        v-for="(variation, index) in getValueFromSource(product, 'variants', [])"
        :key="index + forceRerenderKey"
        class="d-flex justify-content-between align-items-center mb-4"
      >
        <div>
          <div class="font-weight-bold text-dark">
            {{ getVariationTitle(variation) }}
            <span v-if="getValueFromSource(variation, 'inventory_config.track_quantity', false)" class="text-dark" style="font-weight: 100; font-size: 12px;">
              ({{ variation.inventories.reduce((a, c) => a + c.quantity, 0) }} units left)
            </span>
          </div>
          <div class="text-black-50">{{ formatMoney(getValueFromSource(variation, 'pricing.price')) }}</div>
        </div>

        <div style="min-width: 145px;">
          <span
            v-if="!isInCart(variation._id)"
            style="width: 180px;"
            @click="() => addVariationToCart(variation)"
            :class="{
              'bg-primary': !getValueFromSource(variation, 'inventory_config.track_quantity', false) || getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false) || (getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) > 0),
              'text-white': !getValueFromSource(variation, 'inventory_config.track_quantity', false) || getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false) || (getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) > 0),
              'cursor-pointer': !getValueFromSource(variation, 'inventory_config.track_quantity', false) || getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false) || (getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) > 0),

              'bg-disabled': getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) === 0 && !getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false),
              'text-danger': getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) === 0 && !getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false),
              'cursor-not-allowed': getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) === 0 && !getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false)
            }"
            class="rounded py-2 px-4 d-inline-block text-center"
          >
            <span
              v-if="
                !getValueFromSource(variation, 'inventory_config.track_quantity', false) || getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false) || (getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) > 0)">
              <feather-icon icon="ShoppingCartIcon" />
              <span style="margin-left: 0.75rem; margin-right: 0.75rem;">Add to cart</span>
            </span>

            <span
              v-if="getValueFromSource(variation, 'inventory_config.track_quantity', false) && variation.inventories.reduce((a, c) => a + c.quantity, 0) === 0 && !getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false)"
              class="text-center w-100" style="font-weight: 200;">Out of stock</span>
          </span>

          <span
            v-else
            type="primary"
            class="rounded bg-primary py-2 px-4 d-inline-block"
            style="width: 180px;"
          >
            <feather-icon
              class="text-white cursor-pointer"
              size="20"
              icon="MinusCircleIcon"
              @click="decreaseQuantity(variation)"
            />

            <span
              class="mx-1 text-white">Quantity:
              <span>{{ getVariationQuantity(variation) }}</span>
            </span>

            <feather-icon
              v-if="!getValueFromSource(variation, 'inventory_config.track_quantity', false) || getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales', false) || showIncreaseCartQuantityButton(variation)"
              class="text-white cursor-pointer"
              size="20"
              icon="PlusCircleIcon"
              @click="increaseQuantity(variation)"
            />
          </span>
        </div>
      </div>

      <!-- <pre>{{  product  }}</pre> -->

      <div class="d-flex justify-content-center mt-5 row">
        <button @click="$emit('close')" :class="`btn btn-md btn-md-lg btn-outline-light text-primary rounded col-5 shadow mt-4 mt-sm-0`">
          Continue Shopping
        </button>

        <button @click="goToCheckout" class="btn btn-md btn-md-lg btn-primary rounded ml-4 col-6 shadow mt-4 mt-sm-0">
          View Cart and Checkout
        </button>
      </div>
    </div>
  </template>

<script>
import ProductMixin from '@/core/mixins/products'

import { trimEnd } from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AddToCart',
  mixins: [ProductMixin],
  computed: {
    ...mapState(['settings', 'cart']),
    ...mapGetters('cart', ['isInCart']),
    loading() {
      return this.$store.state.user.loading;
    },
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      forceRerenderKey: 0
    };
  },
  methods: {
    trimEnd,
    goToCheckout() {
      this.$router.push({ name: 'shopping-cart' })
    },
    decreaseQuantity(variation) {
      const orderInCart = this.getOrderByVariationId(variation._id)
      orderInCart && this.decreaseProductQuantity(orderInCart.id)
      this.forceRerenderKey++
    },
    increaseQuantity(variation) {
      const orderInCart = this.getOrderByVariationId(variation._id)
      if (orderInCart){

        const track_quantity = this.getValueFromSource(variation, 'inventory_config.track_quantity', false);
        const allow_out_of_stock_sales = this.getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales');
        if (track_quantity && orderInCart.quantity > variation.inventories.reduce((a, c) => a + c.quantity, 0) && !allow_out_of_stock_sales){
          return;
        }

        this.increaseProductQuantity(orderInCart.id);
      }
    },
    getVariationQuantity(variation) {
      const orderInCart = this.getOrderByVariationId(variation._id)
      return orderInCart ? orderInCart.quantity : 0
    },
    updateProductType(e) {
      e.preventDefault();
      this.form.validateFields((err, payload) => {
        if (!err) {
          this.$emit('save', payload)
        }
      });
    },
    addVariationToCart(variation) {
      const track_quantity = this.getValueFromSource(variation, 'inventory_config.track_quantity', false);
      const allow_out_of_stock_sales = this.getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales');

      if (track_quantity && variation.inventories.reduce((a, c) => a + c.quantity, 0) === 0 && !allow_out_of_stock_sales){
        return;
      }

      this.$store.commit(
        'cart/ADD_TO_CART',
        {
          quantity: 1,
          variation_id: variation._id,
          product_id: this.product._id,
          product_title: this.product.label,
          price: variation.pricing.price || 0,
          variation_title: this.getVariationTitle(variation),
          variation_image_url: this._get(variation, 'media.path'),
          product_image_url: this.product.src || this.getProductImage(this.product),
        }
      )
    },
    showIncreaseCartQuantityButton(variation){
      const orderInCart = this.getOrderByVariationId(variation._id)
      if (orderInCart){
        const track_quantity = this.getValueFromSource(variation, 'inventory_config.track_quantity', false);
        const allow_out_of_stock_sales = this.getValueFromSource(variation, 'inventory_config.allow_out_of_stock_sales');
        if ( allow_out_of_stock_sales || (track_quantity && orderInCart.quantity < variation.inventories.reduce((a, c) => a + c.quantity, 0)) ){
          return true;
        }
      }

      return false
    }
  },
};
</script>
