export default {
  namespaced: true,
  state: {
    unread_notifications: []
  },
  getters: {
    unread_notifications: (state) => state.notifications
  },
  mutations: {
    UPDATE_UNREAD_NOTIFICATIONS(state, value) {
      state.unread_notifications = [...state, value]
    }
  }
}
