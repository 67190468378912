export const AuthEndpoints = {
  // Auth Endpoints
  registerEndpoint: '/auth/register',
  registerBrandEndpoint: '/auth/register/brand',
  registerPrintEndpoint: '/auth/register/print',
  registerConsumerEndpoint: '/auth/register/consumer',
  resendConfirmEmailEndpoint: '/auth/account/confirmation/resend',
  loginEndpoint: '/auth/login',
  logEndpoint: '/auth/login',
  logoutEndpoint: '/auth/logout',
  logsEndpoint: '/auth/logs',
  confirmTokenValidityEndpoint: '/auth/check',
  confirmEmailEndpoint: '/auth/confirm-email',
  confirmPhoneEndpoint: '/auth/confirm-phone',
  requestPhoneVerificationEndpoint: '/auth/request-phone-verification',
  requestPasswordResetEndpoint: '/auth/request-password-reset',
  checkPasswordResetValidityEndpoint: '/auth/check-password-reset-token',
  resetPasswordEndpoint: '/auth/reset-password',
  changePasswordEndpoint: '/auth/password/change',
  completeOnboardEndpoint: '/auth/onboard',
  updateUserProfileEndpoint: '/auth/profile',
  userSupportTicketsEndpoint: '/auth/support-tickets',
  userNotificationsEndpoint: '/auth/notifications',
  onboardingEndpoint: '/auth/onboarding',
}
