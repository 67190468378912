import { jwtConfig } from '@/services/jwt/jwtConfig';

export const StorageService = {
  setToken: (token) => {
    localStorage.setItem(jwtConfig.storageTokenKeyName, token);
  },

  setPasswordResetToken: (token) => {
    localStorage.setItem(jwtConfig.storagePasswordResetTokenKeyName, token);
  },

  setAccountConfirmationToken: (token) => {
    localStorage.setItem(jwtConfig.storageConfirmAccountTokenKeyName, token);
  },

  setPhoneVerificationToken: (token) => {
    localStorage.setItem(jwtConfig.storageConfirmPhoneToken, token);
  },

  getAccessToken: () => {
    return localStorage.getItem(jwtConfig.storageTokenKeyName);
  },

  getAccountConfirmationToken: () => {
    return localStorage.getItem(jwtConfig.storageConfirmAccountTokenKeyName)
  },

  getPasswordResetToken: () => {
    return localStorage.getItem(jwtConfig.storagePasswordResetTokenKeyName)
  },

  getPhoneVerificationToken: () => {
    return localStorage.getItem(jwtConfig.storageConfirmPhoneToken)
  },

  clearAccessToken: () => {
    localStorage.removeItem(jwtConfig.storageTokenKeyName);
  },

  clearAccountConfirmationToken: () => {
    localStorage.removeItem(jwtConfig.storageConfirmAccountTokenKeyName);
  },

  clearPasswordResetToken: () => {
    localStorage.removeItem(jwtConfig.storagePasswordResetTokenKeyName);
  },

  clearPhoneConfirmationToken: () => {
    localStorage.removeItem(jwtConfig.storageConfirmPhoneToken);
  },

  //  mfa token
  setMfaAccessToken: (token) => {
    localStorage.setItem(jwtConfig.storageMFATokenKeyName, token);
  },

  setMfaDeactivationToken(value) {
    localStorage.setItem(jwtConfig.storageMFADeactivationToken, value)
  },

  getMfaDeactivationToken() {
    return localStorage.getItem(jwtConfig.storageMFADeactivationToken);
  },

  setMFAPasswordResetToken(value) {
    localStorage.setItem(jwtConfig.storageMFAPasswordResetToken, value)
  },

  getMFAPasswordResetToken() {
    return localStorage.getItem(jwtConfig.storageMFAPasswordResetToken)
  },

  clearMFAPasswordResetToken() {
    localStorage.removeItem(jwtConfig.storageMFAPasswordResetToken)
  },

  getMfaAccessToken() {
    return localStorage.getItem(jwtConfig.storageMFATokenKeyName);
  },

  clearMfaAccessToken() {
    localStorage.removeItem(jwtConfig.storageMFATokenKeyName)
  },

  clearMfaDeactivationToken() {
    localStorage.removeItem(jwtConfig.storageMFADeactivationToken)
  },
}
