import { MfaEndpoints } from '@/services/jwt/endpoints/mfa';
import { UserEndpoints } from '@/services/jwt/endpoints/user';
import { AuthEndpoints } from '@/services/jwt/endpoints/auth';
import { MediaEndpoints } from '@/services/jwt/endpoints/media';
import { StoreEndpoints } from '@/services/jwt/endpoints/store';
import { OrderEndpoints } from '@/services/jwt/endpoints/order';
import { SearchEndpoints } from '@/services/jwt/endpoints/search';
import { PublicEndpoints } from '@/services/jwt/endpoints/public';
import { AdminSettingsEndpoints } from '@/services/jwt/endpoints/settings';
import { NotificationsEndpoints } from '@/services/jwt/endpoints/notifications';
import { AdminProductsEndpoints } from '@/services/jwt/endpoints/admin-products';
import { AdminCustomizationEndpoints } from '@/services/jwt/endpoints/admin-customizations';
import { AdminPermissionEndpoints, AdminUserEndpoints, AdminDashboardEndpoints } from '@/services/jwt/endpoints/admin';

export const jwtConfig = {
  auth: AuthEndpoints,
  mfa: MfaEndpoints,
  users: UserEndpoints,
  admins: AdminUserEndpoints,
  adminDashboard: AdminDashboardEndpoints,
  search: SearchEndpoints,
  public: PublicEndpoints,
  adminProducts: AdminProductsEndpoints,
  store: StoreEndpoints,
  permission: AdminPermissionEndpoints,
  orders: OrderEndpoints,
  media: MediaEndpoints,
  adminCustomizations: AdminCustomizationEndpoints,
  notifications: NotificationsEndpoints,
  settings: AdminSettingsEndpoints,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageMFATokenKeyName: 'mfaAccessToken',
  storageMFADeactivationToken: 'mfaDeactivationToken',
  storageMFAPasswordResetToken: 'mfaPasswordResetToken',
  storageConfirmAccountTokenKeyName: 'confirmAccountToken',
  storagePasswordResetTokenKeyName: 'passwordResetToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageConfirmPhoneToken: 'confirmPhoneToken',
}
