<template>
  <div id="app">
    <localization></localization>
  </div>
</template>

<script>
import Localization from '@/localization'
import * as PusherPushNotifications from '@pusher/push-notifications-web';

import { mapState } from 'vuex'
import { StorageService } from '@/services/jwt/storageService';
import { CustomPusherNotificationService } from '@/services/PusherNotificationService';

export default {
  name: 'app',
  components: { Localization },
  computed: {
    ...mapState(['settings']),
    authorized() {
      return this.$store.state.auth.isLoggedIn
    },
    userData() {
      return this.$store.state.auth.userData
    },
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
  },
  created() {
    CustomPusherNotificationService.init();
    this.resetVuexPersistData();
    this.$socket.onAny((event, ...args) => {
      console.log('socket event ', event, args)
    });
    this.$socket.on('connect_error', (err) => {
      console.log('Socket connection error', err)
      if (err.message === 'not authorized') {
        console.error('A Connection Error', err)
      }
      console.error('A Connection Error', err)
    });
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized) {
        this.$socket.auth = { token: StorageService.getAccessToken() }
        this.$socket.connect()
        const user_id = this.userData._id
        CustomPusherNotificationService.setupAuthenticatedUsers(user_id)
      }
    },
    '$route'(to) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods: {
    initializeSockets() {
      this.$socket.onAny((event, ...args) => {
        console.log('socket event ', event, args)
      });
      this.$socket.on('connect_error', (err) => {
        if (err.message === 'not authorized') {
          this.$notification.warn({
            message: 'Chatroom Connection Failed',
            description: 'You are not authorized to access chat'
          })
          console.error('A Connection Error', err)
        }
        console.error('A Connection Error', err)
      });
    },
    async initializePusherPusherNotification() {
      try {
        const serviceWorkerRegistration = await window.navigator.serviceWorker.ready;

        const beamsClient = new PusherPushNotifications.Client({
          instanceId: '55411a20-bf90-4a04-9f32-7eed5f12c5c1',
          serviceWorkerRegistration: serviceWorkerRegistration,
        });

        await beamsClient.start();

        await beamsClient.addDeviceInterest('debug-pentstore')
        await beamsClient.addDeviceInterest('pentstore')

        const deviceId = await beamsClient.getDeviceId();
        console.log('Successfully registered with Beams. Device ID:', deviceId)

        const interests = await beamsClient.getDeviceInterests();
        console.log('Current interests ', interests)
      } catch (error) {
        console.log('Error Occurred while initializing beam ', error)
      }
    },
  },
  unmounted() {
    this.$socket.off('connect_error');
  },
  methods: {
    resetVuexPersistData() {
      try {
        const persisted_store = JSON.parse(localStorage.getItem('vuex'))
        if (persisted_store.cart) {
          if (persisted_store.cart.cart && !Array.isArray(persisted_store.cart.cart)) {
            localStorage.removeItem('vuex')
            this.$store.commit("cart/CLEAR_CART")
          }
        }
      } catch (error) {
        console.log("Error resetting vuex store", error)
      }
    }
  }
}
</script>
