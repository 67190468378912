import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

export default {
  state: {
    isConnected: false,
    socketMessage: ''
  },
  mutations: {
    SOCKET_CONNECT(state) {
      state.isConnected = true;
    },

    SOCKET_DISCONNECT(state) {
      state.isConnected = false;
    },

    SOCKET_MESSAGECHANNEL(state, message) {
      state.socketMessage = message
    }
  }
}
