export const AdminUserEndpoints = {
  root: '/admin-users',
  store: '/stores'
}

export const AdminPermissionEndpoints = {
  rootPolicy: '/permissions/policy',
  rootSchema: '/permissions/schema',
  rootUserRole: '/permissions/user-role',
  rootResource: '/permissions/resources',
  rootResourceActions: '/permissions/resource-action',
}

export const AdminDashboardEndpoints = {
  root: '/dashboard'
}
