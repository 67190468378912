<template>
  <div style="position: relative;" class="w-100 ml-3">
    <b-row class="w-100">
      <b-input-group class="input-group-merge">
        <b-form-input
          v-model="query"
          placeholder="Search"
          class="autocomplete-search"
          @input="(val) => $emit('update:query', val)"
          @focus="isOpen = !isOpen"
          @blur="() => {}"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="SearchIcon"
            class="text-muted"
          />
        </b-input-group-append>
      </b-input-group>
    </b-row>

    <b-row style="position: absolute; z-index: 1000; max-height: 250px; overflow-y: scroll;" class="w-100 mt-1">
      <b-list-group v-show="options.length && isOpen && !loading" class="w-100">
        <b-list-group-item v-for="(opt, i) in options" :key="i" href="#" @click="onOptionSelected(opt)">
         {{ opt.label }}
        </b-list-group-item>
      </b-list-group>

      <b-list-group v-show="!options.length && isOpen && !!query && !loading" class="w-100">
        <b-list-group-item disabled>
          No result available
        </b-list-group-item>
      </b-list-group>

      <b-list-group v-show="loading" class="w-100">
        <b-list-group-item disabled>
          <span>Loading...</span>
        </b-list-group-item>
      </b-list-group>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BFormInput,
  BInputGroup,
  BListGroup,
  BInputGroupAppend,
  BListGroupItem,
} from 'bootstrap-vue'

import { get, debounce } from 'lodash'
import { SearchServices } from '@/services/jwt/api/search';

export default {
  components: {
    BRow,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    searchStores: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      query: this.search,
      selectedValue: '',
      options: [],
      debounceInitiateSearch: null,
    }
  },
  watch: {
    query: {
      handler() {
        if (this.query === this.selectedValue) return;
        if (!this.query && this.selectedValue) {
          this.$emit('selected', '');
        }
        this.debounceInitiateSearch()
      },
      immediate: false,
    },
  },
  created() {
    this.debounceInitiateSearch = debounce(this.fetchSearchData, 500)
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (!event.target.classList.contains('autocomplete-search')) {
        this.isOpen = false;
      }
    },
    onOptionSelected(opt) {
      this.$emit('selected', opt.value);
      this.selectedValue = opt.label
      this.query = opt.label;
    },
    async fetchSearchData() {
      try {
        this.loading = true;

        if (!this.query) return;

        const Service = this.searchStores ? SearchServices.fetchStores : SearchServices.fetch

        const response = await Service('users', {
          search: this.query,
          limit: 1000,
        });

        const { data: results } = response.data;
        this.options = results
      } catch (error) {
        const error_message = get(error, 'response.data.message') || error.message
        console.log('error_message', error_message)
        this.$notification.error({
          title: 'Error!',
          message: error_message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style>

</style>
