import apiClient, { publicClient } from '@/services/axios';
import { jwtConfig } from '@/services/jwt/jwtConfig';

export const PublicServices = {
  searchProducts: (params) => {
    return apiClient.get(`${jwtConfig.public.root}/search-products`, { params })
  },
  fetchProducts: (params) => {
    return publicClient.get(`${jwtConfig.public.root}/products`, { params })
  },
  fetchCurrency: (params) => {
    return apiClient.get(`${jwtConfig.public.root}/currency`, { params }, {
      headers: {
        'Content-Type': 'text/plain'
      }
    })
  },
  fetchFeaturedProducts: (params) => {
    return apiClient.get(`${jwtConfig.public.root}/products/featured`, { params })
  },
  fetchProduct: (idOrSlug) => {
    return apiClient.get(`${jwtConfig.public.root}/products/${idOrSlug}`)
  },
  fetchStores: (params) => {
    return apiClient.get(`${jwtConfig.public.root}/stores`, { params })
  },
  fetchProductCategories: (params) => {
    return apiClient.get(`${jwtConfig.public.root}/products/categories`, { params })
  },
  fetchProductPriceRange: (params) => {
    return apiClient.get(`${jwtConfig.public.root}/products/prices/range`, { params })
  },

  pruneCart: (cart) => {
    return apiClient.post(`${jwtConfig.public.root}/prune-cart`, cart)
  },

  uploadAttachment(formData) {
    return apiClient.post('attachments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  pruneCart: (data) => {
    return apiClient.post(`${jwtConfig.public.root}/prune-cart`, data)
  },
}
