<template>
  <section>
    <div>
      <div v-if="notifications.length > 0">
        <div v-for="(notification, index) in notifications" :key="index"
             class="notification-item pt-3 pb-3 border-top-0 border-bottom"
             @click="readNotification(notification)"
        >
          <div class="d-flex align-items-center">
            <div class="ml-2 width-10p">
              <span class="material-symbols-rounded">
                notifications
              </span>
            </div>
            <div class="flex-grow-1">
              <!-- top level -->
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="m-0 p-0">
                      <span class="text-capitalize">({{ getValueFromSource(notification, 'resource.value', '') }})</span>
                      {{ getValueFromSource(notification, 'title') }}
                    </h6>
                  </div>
                  <div>
                    <small>{{ generateDistance(getValueFromSource(notification, 'created', '')) }}</small>
                  </div>
                </div>
              </div>
              <!-- notifications message -->
              <div>
                <div class=" -ellipsis-1">
                  <p class="">{{ getValueFromSource(notification, 'message') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-center border-bottom">
          <div>
            <img src="@/core/assets/gifs/not-found.gif" class="not-found-image" width="180px" alt="not-found">
          </div>
          <div class="pb-3">No unread notifications</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { get } from 'lodash';
import { formatDistance, parseISO } from 'date-fns';
import { NotificationsServices } from '@/services/jwt/api/notifications';

export default {
  name: 'NotificationsDropdownList',
  computed: {
    userData() {
      return this.$store.state.auth.userData
    }
  },
  props: {
    notifications: {
      type: Array,
      required: false
    },
    fetch: {
      type: Function,
      required: true
    }
  },
  methods: {
    generateDistance(date) {
      if (!date) return;
      const actual_date = parseISO(date)
      return formatDistance(actual_date, new Date(), {
        addSuffix: true,
        includeSeconds: true
      })
    },
    async readNotification(notification) {
      try {
        const id = get(notification, '_id');

        const payload = { read: true }
        await NotificationsServices.updateNotificationStatus(id, payload);
        this.$emit('refetch');

        if (this.isCurrentUserCustomer){
          this.$router.push({
            name: 'order-details',
            params: {
              id: get(notification, 'resource.id')
            },
            query: {
              ts: new Date().getTime()
            }
          }).catch((e) => {})
        }

        if (this.isCurrentUserStore){
          const userId = get(notification, 'author.user_id')
          const routeName = userId === this.currentUser._id ? "order-details" : "store-order-detail"
          this.$router.push({
            name: routeName,
            params: {
              id: get(notification, 'resource.id')
            },
            query: {
              ts: new Date().getTime()
            }
          }).catch((e) => {
            console.log('error', e)
          })
        }

        if (this.isCurrentUserAdmin){
          this.$router.push({
            name: 'admin-order-detail',
            params: {
              id: get(notification, 'resource.id')
            },
            query: {
              ts: new Date().getTime()
            }
          }).catch(() => {})
        }
      } catch (e) {
        this.showErrorNotification(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notification-item {
  small {
    word-spacing: -2px;
  }
}
.notification-item:hover {
  border-radius: 5px;
  cursor: pointer;
  background-color: #e1eec0;
}

</style>
