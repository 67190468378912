import { canNavigate } from '@/core/utils/utils';
import store from '@/store';

import { get } from 'lodash';
import { jwtConfig } from '@/services/jwt/jwtConfig';
import { StorageService } from '@/services/jwt/storageService';

export const sharedPages = [
  {
    path: '/403',
    name: 'error-403',
    component: () => import(/* webpackPrefetch: true */ '@/components/cleanui/system/Errors/403'),
    meta: {
      roles: ['admin', 'store', 'customer', 'anonymous', 'print'],
      requireAuth: false
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import(/* webpackPrefetch: true */ '@/components/cleanui/system/Errors/403'),
    meta: {
      roles: ['admin', 'store', 'customer', 'anonymous', 'print'],
      requireAuth: false
    },
  },
  {
    path: '/400-country-code',
    name: 'error-400-country-code',
    component: () => import(/* webpackPrefetch: true */ '@/components/cleanui/system/Errors/400-Country-Code'),
    meta: {
      roles: ['admin', 'store', 'customer', 'anonymous', 'print'],
      requireAuth: false
    },
  },
  {
    path: '*',
    name: 'error-404',
    component: () => import(/* webpackPrefetch: true */ '@/components/cleanui/system/Errors/404'),
    meta: {
      roles: ['admin', 'store', 'customer', 'anonymous', 'print'],
      requireAuth: false
    },
  },
]

function ensureUserHasRouteAccess(to, next) {
  const { user_type, store: userStore, _id } = store.getters['auth/userData']
  const { roles: allowed_roles = [], permission } = to.meta
  if (allowed_roles.length > 0) {
    if (!allowed_roles.includes(user_type)) {
      return next({ name: 'error-403' })
    }
  }

  if (user_type === 'store' && permission) {
    const isStoreOwner = get(userStore, 'owner.is_owner');
    if (isStoreOwner) { return next() }

    if (!get(userStore, `owner.permissions.${permission}`, false)) {
      return next({ name: 'error-403' })
    }
  }

  next()
}

// logs user out if token is invalid/expired
// if user is accessing unauthenticated route however, user should be allowed to
// proceed without any auth check
async function ensureTokenValidity(to, _, next) {
  try {
    const accessToken = StorageService.getAccessToken();
    if (!accessToken) throw new Error('Not authorized');

    await store.dispatch('auth/AUTH_CHECK')
    next()
  } catch (error) {
    console.log('error', error)
    localStorage.removeItem(jwtConfig.storageTokenKeyName)

    if (to.name === 'general-login') { return next() }
    return next({ name: 'general-login' })
  }
}

export async function routeMiddleware(to, from, next) {
  if (to.name === from.name) { return next() }
  const toPathRequiresAuth = get(to, 'meta.requireAuth', false);

  const hasAccessToken = !!StorageService.getAccessToken();

  if (!hasAccessToken && toPathRequiresAuth) {
    sessionStorage.setItem('last_page_accessed', to.path)
    return next({ name: 'general-login' })
  }

  if (hasAccessToken) {
    await ensureTokenValidity(to, from, next)
  }

  if (toPathRequiresAuth) {
    if (!hasAccessToken) { return next({ name: 'general-login' }) }
    return ensureUserHasRouteAccess(to, next)
  }

  if (!canNavigate(to)) {
    return next(false)
  }

  next()
}
