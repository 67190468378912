import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import auth from './auth'
import cart from './cart'
import socket from './socket'
import settings from './settings'
import currencies from './currencies'
import navigation from './navigation'
import notifications from './notifications'
import customization from './customization'

import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['cart', 'customization']
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    auth,
    settings,
    navigation,
    notifications,
    socket,
    cart,
    currencies,
    customization
  },
  plugins: [vuexLocal.plugin]
})
