/**
 * Stores custom orders prior to checkout
*/

export default {
  namespaced: true,
  state: {
    order: {}
  },
  getters: {
    isInCart: state => (variation_id) => state.order.variation_id === variation_id,
    orderQuantity: state => () => state.order.quantity || 0
  },
  mutations: {
    SET_ORDER(state, orderData) {
      state.order = {
        quantity: 1,
        ...orderData,
      };
    },
    DECREASE_QUANTITY(state, variation_id) {
      if (state.order && state.order.variation_id === variation_id) {
        state.order.quantity--
      }
    },
    INCREASE_QUANTITY(state, variation_id) {
      if (state.order && state.order.variation_id === variation_id) {
        state.order.quantity++
      }
    },
    RESET(state) {
      state.order = {}
    },
    REMOVE_FROM_CART(state, variation_id) {
      if (state.order && state.order.variation_id === variation_id) {
        state.order = {}
      }
    }
  },
  actions: {},
}
