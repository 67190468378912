export const storeNavigation = [
  {
    category: true,
    title: 'Store Home',
  },
  {
    title: 'Dashboard',
    key: 'home',
    icon: 'home',
    url: '/store/dashboard'
  },
  // {
  //   title: 'Inventory',
  //   key: 'inventory',
  //   icon: 'barcode',
  //   url: '/store/inventory'
  // },
  // {
  //   title: 'Transfers',
  //   key: 'transfers',
  //   icon: 'car',
  //   url: '/store/products'
  // },
  {
    category: true,
    title: 'Sales & Reports',
    permission: ['can_see_orders', 'can_generate_report']
  },
  {
    title: 'Orders',
    key: 'orders',
    icon: 'line-chart',
    url: '/store/orders',
    permission: 'can_see_orders'
  },
  {
    title: 'Report',
    key: 'reports',
    icon: 'bar-chart',
    url: '/store/reports',
    permission: 'can_generate_report'
  },
  {
    category: true,
    title: 'Config',
  },
  {
    title: 'Products',
    key: 'products',
    icon: 'bars',
    url: '/store/products',
    permission: 'can_see_products'
  },
  {
    title: 'Settings',
    key: 'settings',
    icon: 'api',
    url: '/store/settings'
  },
]
