
import axios from 'axios'
import store from '@/store'

import { get } from 'lodash';
import { StorageService } from '@/services/jwt/storageService';
import { checkServerDownStatus, handleInvalidAccessToken } from '@/services/axios/request-utils';

const {
  VUE_APP_PLATFORM = '',
  VUE_APP_API_BASE_URL = '',
  VUE_APP_COUNTRY_CODE = '',
  VUE_APP_ROUTE_PREFIX = '',
  VUE_APP_ADMIN_API_BASE_URL = '',
} = process.env;

export const baseUrl = VUE_APP_PLATFORM === 'admin' ? VUE_APP_ADMIN_API_BASE_URL : VUE_APP_API_BASE_URL

const apiClient = axios.create({
  baseURL: baseUrl,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

export const publicClient = axios.create({
  baseURL: VUE_APP_API_BASE_URL
})

apiClient.interceptors.request.use(request => {
  // new request here
  store.commit('user/SET_STATE', {
    loading: true,
  })

  request.headers['X-Country-Code'] = VUE_APP_COUNTRY_CODE
  request.headers['X-Route-Prefix'] = VUE_APP_ROUTE_PREFIX

  const access_token = StorageService.getAccessToken()
  const hasCustomToken = !!get(request, 'headers.Authorization')
  if (!hasCustomToken && access_token) {
    request.headers.Authorization = `Bearer ${access_token}`
    request.headers.AccessToken = access_token
  }
  return request
})

apiClient.interceptors.response.use(
  response => {
    store.commit('user/SET_STATE', { loading: false })
    return response;
  },
  error => {
    const is_server_down = checkServerDownStatus(error)

    if (!is_server_down) {
      handleInvalidAccessToken(error)
    }
    store.commit('user/SET_STATE', {
      loading: false,
    })

    return Promise.reject(error)
  },
)

export default apiClient
