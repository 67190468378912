<script>
import {
  XIcon,
  EyeIcon,
  UserIcon,
  BookIcon,
  CodeIcon,
  LockIcon,
  EditIcon,
  CopyIcon,
  MailIcon,
  DiscIcon,
  HashIcon,
  FileIcon,
  SendIcon,
  PlusIcon,
  BellIcon,
  TrashIcon,
  Edit2Icon,
  ImageIcon,
  ClockIcon,
  EyeOffIcon,
  Trash2Icon,
  CircleIcon,
  SlidersIcon,
  LogOutIcon,
  SearchIcon,
  LayersIcon,
  GithubIcon,
  FacebookIcon,
  SettingsIcon,
  FileTextIcon,
  ScissorsIcon,
  PaperclipIcon,
  ClipboardIcon,
  ChevronUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DollarSignIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  AlertCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  CheckCircleIcon,
  MoreVerticalIcon,
  ChevronRightIcon,
  ShoppingCartIcon,
  MoreHorizontalIcon,
} from 'vue-feather-icons'

const icons = {
  XIcon,
  EyeIcon,
  BellIcon,
  CopyIcon,
  UserIcon,
  BookIcon,
  CodeIcon,
  LockIcon,
  EditIcon,
  MailIcon,
  DiscIcon,
  HashIcon,
  FileIcon,
  SendIcon,
  PlusIcon,
  Edit2Icon,
  TrashIcon,
  ImageIcon,
  ClockIcon,
  Trash2Icon,
  EyeOffIcon,
  CircleIcon,
  SlidersIcon,
  LogOutIcon,
  SearchIcon,
  LayersIcon,
  GithubIcon,
  FacebookIcon,
  SettingsIcon,
  FileTextIcon,
  ScissorsIcon,
  PaperclipIcon,
  ClipboardIcon,
  ChevronUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DollarSignIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  AlertCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  CheckCircleIcon,
  MoreVerticalIcon,
  ChevronRightIcon,
  ShoppingCartIcon,
  MoreHorizontalIcon,
}

export default {
  name: 'FeatherIcon',
  functional: true,
  props: {
    icon: {
      required: true,
      type: [String, Object],
    },
    size: {
      type: String,
      default: '14',
    },
    badge: {
      type: [String, Object, Number],
      default: null,
    },
    badgeClasses: {
      type: [String, Object, Array],
      default: 'badge-primary',
    },
  },
  render(h, { props, data }) {
    // Create Feather Icon
    const svg = h(icons[props.icon], { props: { size: props.size }, ...data })

    // If no badge is provided => Render just SVG
    if (!props.badge) return svg

    // Badge
    const badgeEl = h('span', { staticClass: 'badge badge-up badge-pill', class: props.badgeClasses }, [props.badge])

    // Return span with children
    return h('span', { staticClass: 'feather-icon position-relative' }, [svg, badgeEl])
  },
}
</script>

<style lang="scss">
svg.feather {
  outline: none
}
</style>
