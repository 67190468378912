import { baseUrl } from '@/services/axios';
import { StorageService } from '@/services/jwt/storageService';
import * as PusherPushNotifications from '@pusher/push-notifications-web';

const { VUE_APP_PUSHER_INSTANCE_ID, VUE_APP_PLATFORM } = process.env
export class CustomPusherNotificationService {
  beamClient = null
  static async init() {
    try {
      const service_worker_registration = await window.navigator.serviceWorker.ready;

      this.beamClient = new PusherPushNotifications.Client({
        instanceId: VUE_APP_PUSHER_INSTANCE_ID,
        serviceWorkerRegistration: service_worker_registration,
      });
      await this.beamClient.start();

      const deviceId = await this.beamClient.getDeviceId();

      await this.beamClient.addDeviceInterest('debug-pent-store')
      await this.beamClient.addDeviceInterest('pent-store')

      if (VUE_APP_PLATFORM && VUE_APP_PLATFORM === 'admin') {
        await this.beamClient.addDeviceInterest('pent-store-admin')
      }

      const interests = await this.beamClient.getDeviceInterests();
    } catch (error) {
      console.log('Error Occurred while initializing beam ', error)
    }
  }

  static async addInterest(name) {
    try {
      await this.beamClient.addDeviceInterest(name)
      const interests = await this.beamClient.getDeviceInterests();
    } catch (e) {
      console.warn('Interest Addition Failed', e)
    }
  }

  static async setupAuthenticatedUsers(user_id) {
    try {
      const access_token = StorageService.getAccessToken()
      const beams_token_provider = new PusherPushNotifications.TokenProvider({
        url: `${baseUrl}pusher/beams-auth`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      await this.beamClient.setUserId(user_id, beams_token_provider)
    } catch (e) {
      console.warn('Beam Authentication Failed ', e)
    }
  }

  static async checkUser(current_user_id) {
    try {
      const user_id = await this.beamClient.getUserId();
      if (user_id !== current_user_id) {
        this.beamClient.stop()
      }
    } catch (e) {
      console.warn('Checking beam authentication failed ', e)
    }
  }
}
