var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'cui__layout__grayBackground': _vm.settings.isGrayBackground,
}},[_c('a-layout',{class:{
    'cui__layout__contentMaxWidth': _vm.settings.isContentMaxWidth,
    'cui__layout__appMaxWidth': _vm.settings.isAppMaxWidth,
    'cui__layout__grayBackground': _vm.settings.isGrayBackground,
    'cui__layout__squaredBorders': _vm.settings.isSquaredBorders,
    'cui__layout__cardsShadow': _vm.settings.isCardShadow,
    'cui__layout__borderless': _vm.settings.isBorderless,
  }},[(_vm.settings.menuLayoutType === 'left' && !_vm.settings.isMobileView)?_c('cui-menu-left'):_vm._e(),(_vm.settings.isMobileView)?_c('div',[_c('div',{class:_vm.$style.handler,on:{"click":_vm.toggleMobileMenu}},[_c('div',{class:_vm.$style.handlerIcon})]),_c('a-drawer',{attrs:{"closable":false,"visible":_vm.settings.isMobileMenuOpen,"placement":"left","wrapClassName":_vm.$style.mobileMenu},on:{"close":_vm.toggleMobileMenu}},[_c('cui-menu-left')],1)],1):_vm._e(),_c('a-layout',{staticClass:"ml-3"},[_c('a-layout-content',{staticStyle:{"height":"'30%'"}},[_c('div',{staticClass:"cui__utils__content",class:_vm.routeContainerClass},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view',{class:_vm.routeClass})],1)],1)])],1)],1),(_vm.showFeaturedProducts)?_c('div',{staticClass:"mt-4",staticStyle:{"background-color":"white"}},[_c('FeaturedProducts',{attrs:{"title":"You might also like"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }