export const adminNavigationData = [
  {
    category: true,
    title: "Admin Home",
  },
  {
    title: "Dashboards",
    key: "dashboards",
    icon: "bank",
    // roles: ['admin'], // set user roles with access to this route
    url: "/home",
  },
  {
    category: true,
    title: "User Accounts",
  },
  {
    title: "Administrators",
    key: "administrators",
    icon: "usergroup-add",
    url: "/admin/admin-users",
    permissions: ["admin:CanList"],
  },
  {
    title: "Customers",
    key: "users",
    icon: "team",
    url: "/admin/customers",
    permissions: ["users:CanList"],
  },
  {
    title: "Stores",
    key: "store",
    icon: "appstore",
    url: "/admin/stores",
    permissions: ["users:CanList"],
  },
  {
    category: true,
    title: "Products & Orders",
    permissions: [
      "product_types:CanList",
      "orders:CanList",
      "attributes:CanList",
    ],
  },
  {
    title: "Product Types",
    key: "product-types",
    icon: "tags",
    url: "/admin/products/types",
    permissions: ["product_types:CanList"],
  },
  {
    title: "Orders",
    key: "orders",
    icon: "shopping",
    url: "/admin/orders",
    permissions: ["orders:CanList"],
  },
  {
    title: 'Report',
    key: 'reports',
    icon: 'bar-chart',
    url: '/admin/sales/report',
    permission: ["orders:CanGenerateReport"],
  },
  {
    title: "Pickup Stations",
    key: "pickup-stations",
    icon: "border",
    url: "/admin/pickup-stations",
    permissions: ["pickup_station:CanList"],
  },
  {
    title: "Courier Services",
    key: "courier-services",
    icon: "car",
    url: "/admin/courier-services",
    permissions: ["courier_service:CanList"],
  },
  {
    title: "Coupons",
    key: "admin-coupons",
    icon: "percentage",
    url: "/admin/coupons",
    permissions: ["coupons:CanList"],
  },
  {
    title: "Bank Payments",
    key: "admin-bank-payments",
    icon: "dollar",
    url: "/admin/bank-payments",
    permissions: ["bank_payments:CanList"],
  },
  {
    category: true,
    title: "IAM",
    permissions: ["policy:CanList", "resource:CanList", "user_role:CanList"],
  },
  {
    title: "Permissions",
    key: "resources",
    children: [
      {
        title: "Resources",
        key: "resources",
        icon: "database",
        url: "/admin/resources",
        permissions: ["resource:CanList"],
      },
      {
        title: "Policy",
        key: "policies",
        icon: "profile",
        url: "/admin/policies",
        permissions: ["policy:CanList"],
      },
      {
        title: "User Roles",
        key: "user-roles",
        icon: "team",
        url: "/admin/user-roles",
        permissions: ["user_role:CanList"],
      },
    ],
  },
  {
    category: true,
    title: "General",
    permissions: ["settings:CanList", "currencies:CanList"],
  },
  {
    title: "Settings",
    key: "settings",
    url: "/admin/settings",
    icon: "setting",
    permissions: ["settings:CanList"],
    // roles: ['admin'], // set user roles with access to this route
    // count: 4,
  },
];
