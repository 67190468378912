import { get } from 'lodash'
import { notification } from 'ant-design-vue';
import { StorageService } from '@/services/jwt/storageService';

export const checkServerDownStatus = (error) => {
  const target_status_codes = [413, 408, 504, 502];
  const error_message = 'There was an error communicating with the server. Try again later.';
  const network_error = !error.response && error.message === 'Network Error'
  if (network_error || target_status_codes.includes(get(error, 'response.status'), 500)) {
    notification.error({
      message: 'Error',
      description: error_message
    })
    return true;
  }
}

export const handleInvalidAccessToken = (error) => {
  const code = get(error, 'response.data.code') || get(error, 'response.status')
  if (code === 4300 || code === 403) {
    StorageService.clearAccessToken()
  }
}

export const notifyRequestFailedError = (error) => {
  const { message } = get(error, 'response.data.message');
  notification.error({
    message: 'Sorry',
    description: message
  })
}
