import store from '@/store';
import { StorageService } from '@/services/jwt/storageService';

export const maskPhoneNumber = (phoneNumber) => {
  const masked = phoneNumber.replace(/[\w\W]/g, '*');
  return `${masked.substr(0, phoneNumber.length - 4)}${phoneNumber.substr(phoneNumber.length - 4)}`;
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export const routeGuard = async (to, from, next) => {
  const authorized = !!StorageService.getAccessToken()
  if (authorized) {
    await store.dispatch('auth/AUTH_CHECK')
    next();
  } else {
    next('/auth')
  }
}

export function isExplicitDenied(authorization, urn, actions) {
  return authorization.special_deny.filter(statement => statement.resource === urn).some(entry => actions.every(action => entry.actions.includes(action)));
}

export function isExplicitAllowed(authorization, urn, actions) {
  return authorization.special_allow.filter(statement => statement.resource === urn).some(entry => actions.every(action => entry.actions.includes(action)));
}

export const canNavigate = (to) => {
  const { authorization } = store.getters['auth/userData'];
  const verifier = () => {
    if (to.meta.permissions) {
      const { permissions = [] } = to.meta;
      const permissionsToEvaluate = !Array.isArray(permissions) ? [permissions] : permissions;
      if (permissionsToEvaluate.length === 0) { return true }

      const { resource } = to.meta;
      if (to.params.id && resource) {
        const urn = `urn:pentstore:${resource}:${to.params.id}`;

        if (isExplicitDenied(authorization, urn, permissions)) {
          return false;
        }

        if (isExplicitAllowed(authorization, urn, permissions)) {
          return true
        }
      }

      return permissionsToEvaluate.every(permission => authorization.grants.includes(permission));
    }
    return true;
  }
  return to && to.matched && verifier()
}

export const checkLoginStatus = async (to, from, next) => {
  const access_token_available = !!StorageService.getAccessToken()
  if (access_token_available) await store.dispatch('auth/AUTH_CHECK')
  next()
}

export async function dummyXmlRequest({ file, onSuccess }) {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export function formatNumber(num, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;

    if (num % 1 === 0 && found.threshold === 1) {
      return Math.round(num);
    }

    return formatted;
  }

  return num;
}
