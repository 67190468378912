<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div class="d-flex flex-column h-100 justify-content-center cursor-pointer">
      <a-badge :count="unread_notifications">
        <span :class="$style.icon" class="material-symbols-rounded">notifications</span>
      </a-badge>
    </div>
    <div slot="overlay">
      <div class="card cui__utils__shadow width-400">
        <div class="px-2 pt-4">
          <div>
            <NotificationsDropdownList :notifications="notifications" :fetch="fetchNotifications" @refetch="fetchNotifications"/>
          </div>
          <div class="text-center py-2">
            <router-link class="" :to="{ name: 'notifications-list' }">
              View all
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </a-dropdown>
</template>

<script>
import { NotificationsServices } from '@/services/jwt/api/notifications';
import NotificationsDropdownList from '@/components/shared/notifications/dropdown/index.vue';

export default {
  components: {
    NotificationsDropdownList,
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    }
  },
  data() {
    return {
      notifications: [],
      unread_notifications: 0
    }
  },
  created() {
    this.fetchNotifications();
    this.sockets.subscribe('notification', (data) => {
      this.fetchNotifications();
    });
  },
  methods: {
    async fetchNotifications() {
      try {
        this.loading = true

        const query = {
          limit: 5,
          page: 1,
          status: 'unread',
        }

        const response = await NotificationsServices.fetchNotifications(query);
        this.notifications = this.getValueFromSource(response, 'data.data');
        this.unread_notifications = this.getValueFromSource(response, 'data.unread_count');
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
