<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width: settings.isMenuCollapsed && !settings.isMobileView ? '80px' : settings.leftMenuWidth + 'px',
        height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <b-link to="/" class="w-100 d-flex align-items-center">
            <img width="50px" height="50px" src="@/core/assets/images/logo.png" class="mr-2" alt="logo" />
            <div :class="$style.name">Pent Store </div>
          </b-link>
        </div>
      </div>
      <a-dropdown
        v-if="currentUser.user_type === 'store'"
        :trigger="['click']" :id="'drop'"
        :overlayStyle="{ width: '250px', padding: '0px'}"
        :visible="changeStore"
        class="my-4"
        placement="bottomCenter"
      >
        <div class="mx-3 inline-block ant-dropdown-link justify-content-between d-flex align-items-center text-large cursor-pointer active-store" @click="e => { e.preventDefault(); changeStore = !changeStore; return; } ">
          <h5 class="--elipsis-1 text-white p-0 m-0">{{ getValueFromSource(currentUser, 'store.store_name') }}</h5>
          <span v-if="alternateStoreOptions.length">
            <feather-icon v-if="changeStore" icon="ChevronUpIcon"  size="26" color="white" />
            <feather-icon v-else icon="ChevronDownIcon"  size="26" color="white" />
          </span>
        </div>

        <b-card class="mx-2" slot="overlay" footer-tag="footer" style="padding: 0; margin: 0; background-color: #060f8c;" v-if="alternateStoreOptions.length">
          <div class="store-option" v-for="(store, index) in alternateStoreOptions" :key="index" @click="() => changeCurrentStore(store._id)">{{ store.store_name }}</div>
        </b-card>
      </a-dropdown>
      <vue-custom-scrollbar
        :style="{
          height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',
        }"
      >
        <a-menu
          forceSubMenuRender
          :inlineCollapsed="settings.isMobileView ? false : settings.isMenuCollapsed"
          :mode="'inline'"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="$style.navigation"
        >
          <template v-for="(item, index) in menuData">
            <template v-if="canViewNavMenuItem(item)">
              <template v-if="!item.roles || item.roles.includes(user.role)">
                <a-menu-item-group :key="index" v-if="item.category">
                  <template slot="title">{{ item.title }}</template>
                </a-menu-item-group>
                <item
                  v-if="!item.children && !item.category"
                  :menu-info="item"
                  :styles="$style"
                  :key="item.key"
                />
                <sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key"/>
              </template>
            </template>
          </template>
        </a-menu>
      </vue-custom-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
import store from 'store'
import find from 'lodash/find'
import Item from './partials/item'
import SubMenu from './partials/submenu'
import navigationData from '@/navigation';
import vueCustomScrollbar from 'vue-custom-scrollbar'

import { BLink } from 'bootstrap-vue';
import { mapState, mapGetters } from 'vuex'
import { StoreServices } from "@/services/jwt/api/store"

export default {
  name: 'menu-left',
  components: { vueCustomScrollbar, SubMenu, Item, BLink },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
    ...mapGetters('auth', ['userData']),
    menuData() {
      const { user_type } = this.userData;
      if (user_type === 'store') { return this.storeMenuData };
      return navigationData[user_type] || []
    },
    activeStoreId() {
      return this.getValueFromSource(this.currentUser, 'store._id', '')
    },
    alternateStoreOptions() {
      const stores_data = this.getValueFromSource(this.currentUser, 'stores_data', [])
      return stores_data.map(s => s.store).filter(store => store._id !== this.activeStoreId)
    },
    storeMenuData() {
      const rawNavigation = [ ...navigationData['store'] ]
      return rawNavigation.filter((navigation => {
        if (this.isStoreOwner) { return true }
        const { permission } = navigation;
        if (permission) {
          return this.getValueFromSource(this.storePermissions, permission)
        }
        return true
      }))
    }
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
  },
  data() {
    return {
      selectedKeys: [],
      openKeys: [],
      changeStore: false
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    '$route'() {
      this.setSelectedKeys()
    },
  },
  methods: {
    async changeCurrentStore(store_id) {
      try {
        await StoreServices.changeCurrentStore({ store_id })
        this.$router.go();

      } catch (error) {
        console.log("error changing active store")
        this.showErrorNotification(error)
      }
    },
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    },
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style>
.store-option {
  background-color: #040a5b;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.store-option:hover {
  background-color:white;
  color: #040a5b;
  /* colo */
}

.active-store {
  background-color:#060f8c;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
</style>
