<template>
  <div class="w-100">
    <div class="w-100 d-flex">
      <a-input :size="settings.isMobileView ? 'default' : 'large'" v-model="query"
        placeholder="Search for products here"
        class="autocomplete-search text-dark"
        id="global-search-input"
        @input="(val) => $emit('update:query', val)"
        @focus="isOpen = $event"
        @blur="() => {}"
        @keyup="(event) => event.key === 'enter' ||  event.keyCode === 13 ? goToSearchPage() : null">
        <template slot="prefix">
          <feather-icon icon="SearchIcon" size="16"  />
        </template>
      </a-input>
    </div>

    <b-row class="w-100 mt-1 search-results">
      <b-list-group v-show="options.length && isOpen && !loading" class="w-10">
        <b-list-group-item
          v-for="(option, i) in options"
          :key="i"
          href="#"
          @click="onOptionSelected(option)"
        >
        <div class="d-flex flex-row align-items-center">
          <img
            :src="option.src"
            class="rounded p-2"
            alt="product image"
            height="112"
            width="111"
            style="object-fit: cover;"
          />
          <div class="search-details ml-2">
            <h6 class="-ellipsis-1">{{ option.label }}</h6>
            <div class="text-dark">{{ currencySymbol }} {{ option.price }}</div>
            <span class="-ellipsis-2" v-html="option.description"></span>
          </div>
        </div>

        </b-list-group-item>
      </b-list-group>

      <b-list-group v-show="!options.length && isOpen && !!query && !loading" class="w-100">
        <b-list-group-item disabled>
          No results for "{{ query }}"
        </b-list-group-item>
      </b-list-group>

      <b-list-group v-show="loading" class="w-100">
        <b-list-group-item disabled>
          <span>Loading...</span>
        </b-list-group-item>
      </b-list-group>
    </b-row>
  </div>
</template>

<script>
import productsMixin from '@/core/mixins/products';

import {
  BRow,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { get, debounce } from 'lodash'
import { PublicServices } from '@/services/jwt/api/public';

export default {
  components: {
    BRow,
    BListGroup,
    BListGroupItem,
  },
  mixins: [productsMixin],
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      query: this.search,
      selectedValue: '',
      options: [],
      debounceInitiateSearch: null,
      searchResults: []
    }
  },
  watch: {
    query: {
      handler() {
        this.debounceInitiateSearch()
      },
      immediate: false,
    },
  },
  created() {
    this.debounceInitiateSearch = debounce(this.fetchSearchData, 500)
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const is_global_search_input = event.target.id === 'global-search-input'

      if (!is_global_search_input) {
        this.isOpen = false;
        if (!this.query) {
          this.options = [];
        }
      }
    },
    onOptionSelected(option) {
      const { _id: id, product_category } = option
      if (product_category === 'product') {
        this.$router
          .push({ name: 'product-details', params: { id } })
          .catch(() => {});
      } else {
        this.$router
          .push({ name: 'customize', params: { id } })
          .catch(() => {});
      }
    },
    async fetchSearchData() {
      try {
        this.loading = true;
        if (!this.query || this.query.length < 3) return;

        const response = await PublicServices.fetchProducts({
          page: 1,
          limit: 5,
          search: this.query
        });

        const data = this.getValueFromSource(response, 'data.data');
        this.options = data.map(this.productsTransformer)
      } catch (error) {
        const error_message = get(error, 'response.data.message') || error.message
        this.$notification.error({
          title: 'Error!',
          message: error_message,
        });
      } finally {
        this.loading = false;
      }
    },
    goToSearchPage() {
      this.isOpen = false;
      this.$router
        .push({ path: `/search?query=${this.query}` })
        .catch(() => {});
    },
  },
}
</script>

<style scoped>
.search-results {
  position: absolute;
  z-index: 1000;
  max-height: 500px;
  overflow-y: scroll;
}
.search-details {
  flex-basis: 200px;
}
</style>
