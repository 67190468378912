export default [
  {
    path: 'profile',
    name: 'account-profile',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/account/profile'),
    meta: {
      requireAuth: true,
      title: 'Profile',
      roles: ['customer', 'store']
    },
  },
  {
    path: 'profile/edit',
    name: 'account-profile-edit',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/account/profile/edit'),
    meta: {
      requireAuth: true,
      title: 'Profile Edit',
      roles: ['customer', 'store']
    },
  },
  {
    path: 'orders',
    name: 'account-orders',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/account/orders'),
    meta: {
      requireAuth: true,
      title: 'Orders',
      roles: ['customer', 'store']
    },
  },
  {
    path: 'orders/:id',
    name: 'order-details',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/account/orders/detail'),
    meta: {
      requireAuth: true,
      title: 'Orders',
      roles: ['customer', 'store'],
      routeContainerClass: 'order-detail-container',
      routeClass: 'order-detail-route'
    },
  },
  {
    path: 'viewed/recently',
    name: 'recently-viewed',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/account/recently-viewed'),
    meta: {
      requireAuth: true,
      title: 'Orders',
      roles: ['customer', 'store']
    },
  },
  {
    path: 'security',
    name: 'account-security',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/account/profile/security'),
    meta: {
      requireAuth: true,
      title: 'Account Security',
      roles: ['customer', 'store']
    },
  },
  {
    path: '/notifications',
    name: 'notifications-list',
    component: () => import('@/pages/consumer/account/notifications/list'),
    meta: {
      title: 'Notifications',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
          to: { name: 'notifications' }
        }
      ]
    }
  },
  {
    path: '/bank-payments',
    name: 'account-bank-payments',
    component: () => import('@/pages/consumer/account/bank-payments/list'),
    meta: {
      title: 'Bank Payments',
      requireAuth: true
    }
  }
]
