<template>
<div class="d-flex justify-content-end align-items-center arrow-nav">
  <feather-icon icon="ChevronLeftIcon" size="23.95" class="text-dark" @click="$emit('click','left')"/>
  <feather-icon icon="CircleIcon" size="8.55" class="text-dark" />
  <feather-icon icon="ChevronRightIcon" size="23.95" class="text-dark" @click="$emit('click','right')"/>
</div>
</template>

<script>
export default {
  name: 'HorizontalScrollButton'
}
</script>

<style>
.dot {
  background-color: #eee;
  border: 1px solid #000000 ;
  border-radius: 10px;
  display: inline-block;
  height: 15px;
  width: 15px;
}

.arrow-nav {
  display: block;
}

.arrow-nav svg {
  cursor: pointer;
  opacity: 0.7;
}
</style>
