<template>
  <div :class="$style.topbar">

    <!-- <div class="mr-auto">
      <cui-search />
    </div> -->
    <div class="ml-auto d-none d-sm-block">
      <cui-actions />
    </div>
    <div class="ml-3">
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
// import CuiSearch from './Search'
import CuiActions from './Actions';
import CuiUserMenu from './UserMenu'

export default {
  components: {
    // CuiSearch,
    CuiActions,
    CuiUserMenu,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
