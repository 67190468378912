var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-sider',{class:{
    [_vm.$style.menu]: true,
    [_vm.$style.white]: _vm.settings.menuColor === 'white',
    [_vm.$style.gray]: _vm.settings.menuColor === 'gray',
    [_vm.$style.dark]: _vm.settings.menuColor === 'dark',
    [_vm.$style.unfixed]: _vm.settings.isMenuUnfixed,
    [_vm.$style.shadow]: _vm.settings.isMenuShadow,
  },attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":_vm.settings.isMobileView ? false : true,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView},on:{"collapse":_vm.onCollapse}},[_c('div',{class:_vm.$style.menuOuter,style:({
      width: _vm.settings.isMenuCollapsed && !_vm.settings.isMobileView ? '80px' : _vm.settings.leftMenuWidth + 'px',
      height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
    })},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('b-link',{staticClass:"w-100 d-flex align-items-center",attrs:{"to":"/"}},[_c('img',{staticClass:"mr-2",attrs:{"width":"50px","height":"50px","src":require("@/core/assets/images/logo.png"),"alt":"logo"}}),_c('div',{class:_vm.$style.name},[_vm._v("Pent Store ")])])],1)]),(_vm.currentUser.user_type === 'store')?_c('a-dropdown',{staticClass:"my-4",attrs:{"trigger":['click'],"id":'drop',"overlayStyle":{ width: '250px', padding: '0px'},"visible":_vm.changeStore,"placement":"bottomCenter"}},[_c('div',{staticClass:"mx-3 inline-block ant-dropdown-link justify-content-between d-flex align-items-center text-large cursor-pointer active-store",on:{"click":e => { e.preventDefault(); _vm.changeStore = !_vm.changeStore; return; }}},[_c('h5',{staticClass:"--elipsis-1 text-white p-0 m-0"},[_vm._v(_vm._s(_vm.getValueFromSource(_vm.currentUser, 'store.store_name')))]),(_vm.alternateStoreOptions.length)?_c('span',[(_vm.changeStore)?_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","size":"26","color":"white"}}):_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"26","color":"white"}})],1):_vm._e()]),(_vm.alternateStoreOptions.length)?_c('b-card',{staticClass:"mx-2",staticStyle:{"padding":"0","margin":"0","background-color":"#060f8c"},attrs:{"slot":"overlay","footer-tag":"footer"},slot:"overlay"},_vm._l((_vm.alternateStoreOptions),function(store,index){return _c('div',{key:index,staticClass:"store-option",on:{"click":() => _vm.changeCurrentStore(store._id)}},[_vm._v(_vm._s(store.store_name))])}),0):_vm._e()],1):_vm._e(),_c('vue-custom-scrollbar',{style:({
        height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',
      })},[_c('a-menu',{class:_vm.$style.navigation,attrs:{"forceSubMenuRender":"","inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"mode":'inline',"selectedKeys":_vm.selectedKeys,"openKeys":_vm.openKeys,"inlineIndent":15},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(_vm.canViewNavMenuItem(item))?[(!item.roles || item.roles.includes(_vm.user.role))?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.title))])],2):_vm._e(),(!item.children && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e()]:_vm._e()]:_vm._e()]})],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }