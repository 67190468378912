export default [
  {
    path: 'dashboard',
    name: 'store-dashboard',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/home'),
    meta: {
      title: 'Store Dashboard',
      requireAuth: true,
      roles: ['store']
    },
  },

  {
    path: 'settings',
    name: 'store-settings',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/settings'),
    redirect: { name: 'store-profile' },
    meta: {
      requireAuth: true,
      title: 'Settings',
      roles: ['store']
    },
    children: [
      {
        path: 'profile',
        name: 'store-profile',
        component: () => import(/* webpackPrefetch: true */ '@/pages/store/settings/tabs/Profile'),
        meta: {
          requireAuth: true,
          title: 'Profile',
          roles: ['store']
        },
      },
      {
        path: 'account',
        name: 'store-account',
        component: () => import(/* webpackPrefetch: true */ '@/pages/store/settings/tabs/UsersAndPermissions'),
        meta: {
          requireAuth: true,
          title: 'Users and Permissions',
          roles: ['store']
        },
      },
      {
        path: 'locations',
        name: 'store-locations',
        component: () => import(/* webpackPrefetch: true */ '@/pages/store/settings/tabs/Locations'),
        meta: {
          requireAuth: true,
          title: 'Locations',
          roles: ['store']
        },
      },
      {
        path: 'payments',
        name: 'store-payment-configuration',
        component: () => import(/* webpackPrefetch: true */ '@/pages/store/settings/tabs/Payments'),
        meta: {
          requireAuth: true,
          title: 'Payment Configuration',
          roles: ['store']
        },
      },
      {
        path: 'policies',
        name: 'store-policies',
        component: () => import(/* webpackPrefetch: true */ '@/pages/store/settings/tabs/Policies'),
        meta: {
          requireAuth: true,
          title: 'Policies',
          roles: ['store'],
          permission: 'can_update_store_policies'
        },
      },
    ]
  },
  {
    path: 'locations/new',
    name: 'add-location',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/locations/new'),
    meta: {
      requireAuth: true,
      title: 'Locations',
      roles: ['store'],
    },
  },
  {
    path: 'locations/:id/update',
    name: 'update-location',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/locations/view'),
    meta: {
      requireAuth: true,
      title: 'Update Locations',
      roles: ['store'],
    },
  },

  {
    path: 'products',
    name: 'store-products',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/products/list'),
    meta: {
      title: 'Products',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Products',
          active: true,
        }
      ],
      permission: 'can_see_products'
    },
  },
  {
    path: 'new-product',
    name: 'new-store-product',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/products/create'),
    meta: {
      title: 'New Product',
      requireAuth: true,
      permission: 'can_create_products'
    },
  },
  {
    path: 'products/:id',
    name: 'product-detail',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/products/detail'),
    meta: {
      title: 'Product Detail',
      requireAuth: true,
      permission: 'can_see_products'
    },
  },
  {
    path: 'products/:id/inventory-history',
    name: 'product-inventory-history',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/products/inventory-history'),
    meta: {
      title: 'Product Inventory Adjustment History',
      requireAuth: true,
    },
  },

  {
    path: 'products/:id/variants/:variant_id',
    name: 'product-variant-detail',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/products/variants'),
    meta: {
      title: 'Product Variant Detail',
      requireAuth: true,
      permission: 'can_see_products'
    },
  },

  {
    path: 'products/:id/variants/:variant_id/inventory-history',
    name: 'product-variant-inventory-history',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/products/inventory-history'),
    meta: {
      title: 'Product Variant Inventory Adjustment History',
      requireAuth: true,
      permission: 'can_see_products'
    },
  },

  {
    path: '/store/orders',
    name: 'store-list-orders',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/orders/list'),
    meta: {
      title: 'List Orders',
      requireAuth: true,
      roles: ['store'],
      breadcrumb: [
        {
          text: 'Orders',
          active: true
        },
      ],
      permission: 'can_see_orders'
    },
  },
  {
    path: '/store/orders/verify',
    name: 'store-order-checkout-verify',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/orders/verify'),
    meta: {
      requireAuth: true,
      title: 'Verify Order',
      roles: ['store']
    },
  },
  {
    path: '/store/orders/:id',
    name: 'store-order-detail',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/orders/view'),
    meta: {
      requireAuth: true,
      title: 'Orders Detail',
      roles: ['store'],
      breadcrumb: [
        {
          text: 'Orders',
          to: { name: "store-list-orders" }
        },
        {
          text: 'Order Detail',
          active: true,
        }
      ],
      permission: 'can_see_orders'
    },
  },

  {
    path: '/store/reports',
    name: 'store-reports',
    component: () => import(/* webpackPrefetch: true */ '@/pages/store/reports'),
    meta: {
      title: 'Sales Report',
      requireAuth: true,
      roles: ['store'],
      breadcrumb: [
        {
          text: 'Orders',
          active: true
        },
      ],
      permission: 'can_generate_report'
    },
  },

  {
    path: '/store/notifications',
    name: 'store-notifications-list',
    component: () => import('@/pages/store/notifications/list'),
    meta: {
      title: 'Notifications',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
          to: { name: 'notifications' }
        }
      ]
    }
  },

  {
    path: '/checkout/verify',
    name: 'account-checkout-verify',
    component: () => import(/* webpackPrefetch: true */ '@/pages/consumer/checkout/verify'),
    meta: {
      requireAuth: true,
      title: 'Verify Order',
      roles: ['store']
    },
  },
]
