import { get } from "lodash"
import { nanoid } from "nanoid"

export default {
  namespaced: true,
  state: {
    recently_viewed: [],
    cart: [],
    temporary_used_concessions: 0
  },
  getters: {
    recently_viewed: (state) => state.recently_viewed,
    cartSize: (state) =>
      (state.cart || []).reduce((prev, current) => prev + current.quantity, 0),
    isInCart: (state) => (variation_id) =>
      !!state.cart.find((order) => order.variation_id === variation_id),
    getOrderById: (state) => (id) =>
      state.cart.find((order) => order.id === id),
    getOrderByVariationId: (state) => (variation_id) =>
      state.cart.find((order) => order.variation_id === variation_id),
    getOrderByQuery: (state) => (query) =>
      state.cart.find((order) =>
        Object.keys(query).every((key) => order[key] === query[key])
      ),
    queryOrdersInCart: (state) => (query) =>
      state.cart.filter((order) =>
        Object.keys(query).every((key) => order[key] === query[key])
      ),
    getOrderQuantity: (state) => (id) => {
      const order = state.cart.find((item) => item.id === id);
      if (order) {
        return order.quantity;
      }
      return 0;
    },
  },
  mutations: {
    UPDATE_CART_ITEMS(state, payload) {
      const cart = [...state.cart]
      const { items, deleteItemsWithoutUpdate = false } = payload
      const newCart = []
      for (let i = 0; i < cart.length; i++) {
        const cartItem = cart[i]
        const update = items.find(item => item.id === cartItem.id)
        if (update) {
          if (!update.valid_item) { continue }
          let { quantity } = cartItem

          const track_quantity = get(update, 'inventory_config.track_quantity', false)
          const allow_out_of_stock_sales = get(update, 'inventory_config.allow_out_of_stock_sales', false)

          if (track_quantity && quantity > update.in_stock && !allow_out_of_stock_sales) {
            quantity = update.in_stock
          }
          newCart.push({...cartItem, ...update, quantity })
        } else {
          if (!deleteItemsWithoutUpdate) {
            newCart.push({...cartItem})
          }
        }
      }
      state.cart = newCart;
    },
    ADD_TO_CART(state, cartData) {
      const id = nanoid(7);
      state.cart.push({
        id,
        quantity: 1,
        ...cartData,
      });
    },
    ADD_TO_CART_WITH_ID(state, payload) {
      state.cart.push({
        id: payload.id,
        quantity: 1,
        ...payload.cart_data,
      });
    },
    DECREASE_QUANTITY(state, id) {
      const index = state.cart.findIndex((order) => order.id === id);
      if (index !== -1) {
        const order = state.cart[index];
        if (order.quantity <= 1) {
          // state.cart = state.cart.filter((order) => order.id !== id);
          state.cart.splice(index, 1);
        } else {
          order.quantity--;

          state.cart.splice(index, 1, order);
        }
      }
    },
    INCREASE_QUANTITY(state, id) {
      const index = state.cart.findIndex((order) => order.id === id);
      if (index !== -1) {
        const item = state.cart[index]

        // allow increasing quantity not beyond in stock quantity
        if (item.valid_item !== undefined) {
          const track_quantity = get(item, 'inventory_config.track_quantity', false)
          const allow_out_of_stock_sales = get(item, 'inventory_config.allow_out_of_stock_sales', false)

          if (track_quantity && !allow_out_of_stock_sales) {
            // re-adjust quantity to in_stock quantity
            state.cart[index].quantity = item.in_stock
            return;
          }
        }
        //
        state.cart[index].quantity++;
      }
    },
    CLEAR_CART(state) {
      state.temporary_used_concessions = 0
      state.cart = [];
    },
    SET_CART(state, cartItems) {
      state.cart = cartItems;
    },
    REMOVE_FROM_CART(state, id) {
      state.cart = state.cart.filter((order) => order.id !== id);
    },
    ADD_TO_RECENTLY_VIEWED(state, id) {
      if (!Array.isArray(state.recently_viewed)) {
        state.recently_viewed = [];
      }
      if (!state.recently_viewed.includes(id)) {
        state.recently_viewed.push(id);
      }
      if (state.recently_viewed.length > 10) {
        state.recently_viewed = state.recently_viewed.reverse().slice(0, 10)
      }
    },
  },
  actions: {},
}
