import apiClient from '@/services/axios';
import { jwtConfig } from '@/services/jwt/jwtConfig';

export const NotificationsServices = {
  fetchNotifications: (params) => {
    return apiClient.get(jwtConfig.notifications.root, { params })
  },

  fetchAdminNotifications: (params) => {
    return apiClient.get(jwtConfig.notifications.root, { params })
  },

  updateNotificationStatus: (id, payload) => {
    return apiClient.put(`${jwtConfig.notifications.root}/${id}`, payload)
  },

  markAllNotificationAsRead: (payload) => {
    return apiClient.put(`${jwtConfig.notifications.root}/read`, payload)
  },

  markAllAdminNotificationAsRead: (payload) => {
    return apiClient.put(`${jwtConfig.notifications.root}/read`, payload)
  },

  updateStoreNotificationStatus: (id, payload) => {
    return apiClient.put(`${jwtConfig.notifications.storeRoot}/${id}`, payload)
  },

  markAllStoreNotificationAsRead: (payload) => {
    return apiClient.put(`${jwtConfig.notifications.storeRoot}/read`, payload)
  },

  markAllPrintNotificationAsRead: (payload) => {
    return apiClient.put(`${jwtConfig.notifications.printRoot}/read`, payload)
  }
}
