<template>
  <div :class="$style.breadcrumbs" v-if="breadcrumb">
    <a-breadcrumb>
      <template #separator><feather-icon icon="ChevronRightIcon" size="15"/></template>
      <a-breadcrumb-item>
        <router-link to="/">
          <feather-icon
            icon="HomeIcon"
            size="16"
            class="text-muted"
          />
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item
        v-for="(item, index) in inactiveBreadcrumb"
        :key="index"
      >
        <router-link :to="item.to" class="text-muted">{{ item.text }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item><span class="text-muted">{{ activeBreadcrumb.text }}</span></a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  data() {
    return {
      activeItem: {},
      path: [],
    }
  },
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb
    },
    inactiveBreadcrumb() {
      if (!this.breadcrumb) return;
      return this.breadcrumb.filter(crumb => !crumb.active);
    },
    activeBreadcrumb() {
      if (!this.breadcrumb) return;
      return this.breadcrumb.find(crumb => crumb.active);
    }
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
