import router from '@router';
import { CustomPusherNotificationService } from '@/services/PusherNotificationService';

import { get } from 'lodash';
import { AuthJwtServices } from '@/services/jwt/api/auth';
import { StorageService } from '@/services/jwt/storageService';

const initialState = {
  authorized: false,
  userData: {},
  settings: {},
  isLoggedIn: false
};

const mutations = {
  UPDATE_STATE(state, payload) {
    Object.assign(state, {
      ...payload,
    })
  },
}

const actions = {
  async AUTH_CHECK({
    commit,
    rootState,
  }) {
    // this is supposed to load account validity
    const response = await AuthJwtServices.confirmTokenValidity('web_token')
    const { authorized = false, user = {}, settings } = get(response, 'data.data', {})
    commit('UPDATE_STATE', {
      userData: user,
      settings,
      isLoggedIn: authorized,
    })
  },
  async LOGOUT({ commit, state }) {
    try {
      if (CustomPusherNotificationService && CustomPusherNotificationService.beamClient) {
        await Promise.allSettled([
          CustomPusherNotificationService.beamClient.stop(),
          CustomPusherNotificationService.beamClient.clearAllState()
        ])
      }
      await AuthJwtServices.logout()
    } catch (error) {
      console.log('error', error)
      // logout error is normally irrelevant
    } finally {
      sessionStorage.setItem(`last_page_accessed@${state.userData.id}`, router.currentRoute.path)

      if (state.userData.user_type === 'admin') {
        router.replace({ name: 'general-login' }).catch(() => {})
      } else {
        router.replace('/browse').catch(() => {})
      }

      commit('UPDATE_STATE', {
        authorized: false,
        userData: {},
        settings: {},
        isLoggedIn: false
      });
      StorageService.clearAccessToken();
    }
  },

}

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  userData: (state) => state.userData,
  settings: (state) => state.settings,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
