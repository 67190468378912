
import maskdata from 'maskdata';
import { CustomPusherNotificationService } from '@/services/PusherNotificationService';

import { mapGetters } from 'vuex';
import { get, startCase } from 'lodash';
import { formatMoney, formatDate } from '@/utils';
import { dummyXmlRequest } from '@/core/utils/utils';
import { StorageService } from '@/services/jwt/storageService';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, startOfYear, endOfYear } from 'date-fns';

export default {
  data() {
    return {
      loadingIndicator: '<a-icon type="sync" class="font-size-30" spin />',
      reportTypeOptions: [
        { label: "Monthly Purchases", value: 'monthly_purchases' },
        { label: "Comprehensive Sales & Inventory Report", value: 'comprehensive_inventory_report' },
      ],
    }
  },
  computed: {
    ...mapGetters('currencies', ['currency_symbol']),
    generalAppSettings() {
      return get(this.$store, 'state.auth.settings', {});
    },
    bankSettings() {
      return get(this.$store, 'state.auth.settings.bank_settings', {});
    },
    isLoggedIn() {
      return get(this.$store, 'state.auth.isLoggedIn', {});
    },
    isLoading() {
      return get(this.$store, 'state.user.loading', false);
    },
    currentUser() {
      return get(this.$store, 'state.auth.userData', {});
    },
    adminGrants() {
      return this.getValueFromSource(this.currentUser, 'authorization.grants', []);
    },
    currencySymbol() {
      return 'GH₵'
    },
    adminSpecialDenials() {
      return this.getValueFromSource(this.currentUser, 'authorization.special_deny', []);
    },
    isCurrentUserAdmin() {
      return this.isLoggedIn && this.currentUser && this.currentUser.user_type === "admin"
    },
    storePermissions() {
      return this.getValueFromSource(this.currentUser, 'store.owner.permissions')
    },
    storeId() {
      const { store } = this.currentUser;
      return this.getValueFromSource(store, '_id', "");
    },
    isStoreOwner() {
      const { store } = this.currentUser;
      return this.getValueFromSource(store, 'owner.is_owner', false);
    },
    storeUserCanSeeProducts() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_see_products', false)
      }
      return false;
    },
    storeUserCanCreateProducts() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_create_products', false)
      }
      return false;
    },
    storeUserCanUpdateProducts() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_update_products', false)
      }
      return false;
    },
    storeUserCanDeleteProducts() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_delete_products', false)
      }
      return false;
    },
    storeUserCanUpdateStore() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_update_store', false)
      }
      return false;
    },
    storeUserCanUpdateStoreLocations() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_update_store_locations', false)
      }
      return false;
    },
    storeUserCanUpdateStorePolicies() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_update_store_policies', false)
      }
      return false;
    },
    storeUserCanSeeOrders() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_see_orders', false)
      }
      return false;
    },
    storeUserCanCompleteOrders() {
      const { store } = this.currentUser
      if (store) {
        if (this.isStoreOwner) { return true }
        return this.getValueFromSource(this.storePermissions, 'can_complete_orders', false)
      }
      return false;
    },
    isCurrentUserCustomer() {
      return this.isLoggedIn && this.currentUser && this.currentUser.user_type === "customer"
    },
    isCurrentUserStore() {
      return this.isLoggedIn && this.currentUser && this.currentUser.user_type === "store"
    }
  },
  methods: {
    async logAdminIn(response, destination = 'admin-home') {
      const { user = {}, access_token = '', settings = {} } = this.getValueFromSource(response, 'data.data', {});
      StorageService.setToken(access_token);
      this.$store.commit('auth/UPDATE_STATE', { userData: user, settings, isLoggedIn: true })

      const last_page_accessed = sessionStorage.getItem('last_page_accessed')
      if (last_page_accessed) {
        return this.$router.push(last_page_accessed)
          .then(() => sessionStorage.removeItem('last_page_accessed'))
      }

      await this.$router.push({ name: destination })
      this.$notification.success({
        message: 'Welcome',
        description: 'You have successfully logged in to Pent Store!',
      });
    },
    async logGeneralUserIn(user) {
      const { user_type, _id } = user;

      if (user_type === 'customer') {
        const { cart = [] } = this.$store.state.cart

        if (cart && cart.length > 0) {
          return this.$router.push({ name: 'account-checkout' })
        }
      }

      const last_page_accessed = sessionStorage.getItem(`last_page_accessed@${_id}`)
      if (last_page_accessed) {
        return this.$router.push(last_page_accessed)
          .then(async () => {
            const device_interest = `store-${user_type}`
            await CustomPusherNotificationService.addInterest(device_interest)
            await CustomPusherNotificationService.setupAuthenticatedUsers(_id)
            sessionStorage.removeItem(`last_page_accessed@${_id}`)
          }).catch(() => { })
      }

      await CustomPusherNotificationService.setupAuthenticatedUsers(_id)

      switch (user_type) {
        case 'store':
          await CustomPusherNotificationService.addInterest('store-brand')
          await this.$router.replace({ name: 'store-dashboard' });
          break;
        case 'customer':
          await CustomPusherNotificationService.addInterest('store-client')
          await this.$router.replace({ name: 'client-home' });
          break;
        default:
          await CustomPusherNotificationService.addInterest('store-client')
          await this.$router.replace({ name: 'client-home' });
      }
    },
    async renderChart(element_id, options) {
      const element = document.getElementById(element_id);

      //* using from cdn to reduce bundle size
      // eslint-disable-next-line no-undef
      const chart = new ApexCharts(element, options)
      await chart.render();
    },
    removeEmptyFields(source) {
      const keys = Object.keys(source)
      for (const key of keys) {
        if (!source[key]) {
          delete source[key]
        }
      }
    },
    maskEmail(email) {
      if (!email) return '';
      const options = {
        maskWith: '*',
        unmaskedStartCharactersBeforeAt: 3,
        unmaskedEndCharactersAfterAt: 4,
        maskAtTheRate: false,
      }
      return maskdata.maskEmail2(email, options);
    },
    getValueFromSource(source, path, defaultValue = '') {
      return get(source, path, defaultValue);
    },
    resolveUserRoleVariant(role) {
      if (role === 'customer') return 'primary'
      if (role === 'store') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },
    sentenceCase(text) {
      if (!text) {
        return text;
      }
      let sentenceCase = '';
      const splitText = text.split(' ');
      splitText.forEach(txt => {
        sentenceCase += txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase() + ' ';
      });
      return sentenceCase
    },
    getUserStatusText(status) {
      return status
    },
    resolveStatusTagColor(status) {
      const status_colors = {
        active: 'green',
        inactive: 'red',
        deleted: 'red',
        draft: 'blue',
        published: 'green',
        pending_confirmation: 'blue',
        blocked: 'red'
      }
      return status_colors[status] || '#2db7f5'
    },
    resolveOrderStatusTagColor(status) {
      const status_colors = {
        awaiting_approval: 'orange',
        production: 'blue',
        packaging_and_delivery: 'blue',
        delivered: 'green',
        cancelled: 'red'
      }
      return status_colors[status]
    },
    resolveStatusVariant(status) {
      const secondaryVariants = [
        'draft',
      ];

      const warningVariants = [
        'pending',
        'flagged',
        'inactive',
      ];

      const successVariants = [
        'approved',
        'paid',
        'active',
        'open',
        'delivered',
        'confirmed',
        'low',
        'completed',
        'active',
        'verified',
      ];

      const infoVariants = [
        'archive',
      ];

      const dangerVariants = [
        'deleted',
        'cancelled',
        'rejected',
        'declined',
        'not_fulfilled',
        'banned',
        'high',
        'closed',
        'error',
        'danger',
        'inactive',
        'revoked',
      ];

      if (secondaryVariants.includes(status)) return 'secondary';
      if (warningVariants.includes(status)) return 'warning';
      if (successVariants.includes(status)) return 'success';
      if (infoVariants.includes(status)) return 'info';
      if (dangerVariants.includes(status)) return 'danger';

      return 'primary';
    },
    formatMoney,
    formatDate,
    showErrorNotification(error) {
      const error_message = get(error, 'response.data.message') || error.message
      console.log('error_message', error_message)
      this.$notification.error({
        title: 'Error!',
        message: error_message,
      });
    },
    notify({ variant, title, message }) {
      this.$notification[variant]({
        title,
        message
      })
    },
    getClientOrderStatus(status) {
      const status_details = {
        payment_received: 'Processing',
        pending_design: 'Processing',
        awaiting_approval: 'Processing',
        production: 'Processing',
        packaging_and_delivery: 'Packaging and Delivery',
        cancelled: 'Cancelled',
        delivered: 'Delivered'
      }
      return status_details[status] || 'Processing'
    },
    getClientOrderStatusColor(status) {
      const colorCodes = {
        payment_received: '#108ee9',
        pending_design: '#108ee9',
        awaiting_approval: '#108ee9',
        production: '#fcb017',
        Packaging: '#9ec736',
        cancelled: '#f5222e',
        delivered: '#607821'
      }
      return colorCodes[status]
    },
    canViewNavMenuItem(item) {
      return item.permissions ? item.permissions.every(permission => {
        return this.adminGrants.includes(permission);
      }) : true;
    },
    canViewNavItemGroup(group) {
      return group.children ? group.children.some(child => {
        return this.canViewNavMenuItem(child);
      }) : true;
    },
    canViewNavItemHeader(header) {
      return header.permissions ? header.permissions.some(permission => {
        return this.adminGrants.includes(permission);
      }) : true;
    },
    can(actions) {
      const actionsToEval = !Array.isArray(actions) ? [actions] : actions;
      return actionsToEval.every(action => this.adminGrants.includes(action));
    },
    canView(record) {
      let matchingStatements = []
      const { urn } = record;
      if (urn) {
        const urn_components = urn.split(':')
        const resource = urn_components[2]
        if (resource) {
          const action = `${resource}:CanView`
          matchingStatements = this.adminSpecialDenials.filter(statement => statement.actions.includes(action))
        }
      }
      return matchingStatements.filter(statement => statement.resource === record.urn).length === 0
    },
    dummyXmlRequest,
    getDurationQuery(dateFilter) {
      switch (dateFilter) {
        case 'year':
          return { from: startOfYear(new Date()) }
        case 'week':
          return this.getWeekQuery()
        case 'month':
          return this.getMonthQuery()
        case 'date':
          return this.getDateQuery()
        default:
          return {}
      }
    },
    handleRouteRestriction(failure) {
      if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
        this.$notification.warn({
          message: 'Unauthorized',
          description: 'You do not have access to this page'
        })
      }
    },
    getDateQuery() {
      const today = new Date()
      return {
        to: endOfYear(today),
        from: startOfYear(today),
      }
    },
    getMonthQuery() {
      const today = new Date()

      const from = startOfMonth(today)
      const to = endOfMonth(today)

      return { from, to }
    },
    getWeekQuery() {
      const today = new Date()
      const from = startOfWeek(today)
      const to = endOfWeek(today)

      return { from, to }
    },
    prefetchImages(urls) {
      const heads = document.getElementsByTagName('head')

      if (heads.length > 0) {
        const head = heads[0]

        urls.forEach((url) => {
          const link = document.createElement('link')
          link.rel = 'prefetch'
          link.href = url
          head.appendChild(link)
        })
      }
    },
    validatePresence(value) {
      return value ? 'success' : 'error';
    },
    validatePresenceOfNumber(value) {
      if (value === '') {
        return 'error';
      }
      const regex = /^([0-9]*[.])?[0-9]+$/;
      const is_valid = regex.test(value);
      return is_valid ? 'success' : 'error';
    },
    navigateToRouteAndCatchExceptions(route) {
      this.$router.push(route).catch(() => { console.log(`Failed to navigate to ${route.name}`) })
    },
    extractTextContentFromHTML(s) {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    startCase
  },
}

export const _ = null
