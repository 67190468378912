export default [
  {
    path: '/',
    name: 'general-login',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/social'),
    meta: {
      title: 'Social Sign In',
    },
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import(/* webpackPrefetch: true */'@/pages/consumer/register'),
    meta: {
      requireAuth: false,
      title: 'Consumer Registration',
    },
  },
  {
    path: '/auth/login',
    name: 'general-auth',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/login'),
    meta: {
      title: 'Sign In',
    },
  },
  {
    path: '/auth/mfa/authentication',
    name: 'general-mfa-authentication',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/mfa-authentication'),
    meta: {
      title: 'MFA Authentication',
    },
  },
  {
    path: '/auth/mfa',
    name: 'general-mfa',
    component: () => import(/* webpackPrefetch: true */'@/components/shared/auth/mfa'),
    meta: {
      title: 'Admin Mfa',
    },
  },
  {
    path: '/auth/mfa/activation',
    name: 'mfa-activation',
    component: () => import(/* webpackPrefetch: true */'@/components/shared/auth/mfa-activation'),
    meta: {
      title: 'Admin Mfa Activation',
    },
  },
  {
    path: '/auth/mfa/activation-app',
    name: 'mfa-activation-app',
    component: () => import(/* webpackPrefetch: true */'@/components/shared/auth/mfa-activation-app'),
    meta: {
      title: 'Admin Mfa Activation',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'general-forgot-password',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/forgot-password'),
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/auth/reset-password',
    name: 'general-reset-password',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/reset-password'),
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/auth/mfa/reset-password',
    name: 'general-mfa-reset-password',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/mfa-reset-password'),
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/auth/confirm/email',
    name: 'general-confirm-email',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/confirm-email'),
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: '/auth/confirmed/email',
    name: 'general-confirmed-email',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/confirmed-email'),
    meta: {
      title: 'Email Confirmed',
    },
  },
  {
    path: '/auth/verify/phone',
    name: 'general-verify-phone',
    component: () => import(/* webpackPrefetch: true */'@/pages/auth/verify-phone'),
    meta: {
      title: 'Verify Phone',
      requireAuth: true,
    },
  },
]
