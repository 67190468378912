<template>
  <div class="bg-white" :class="tableContainerClass">
    <h4 v-if="title"> {{ title }}</h4>
    <div class="mb-5" v-if="showAdvancedFeatures">
      <b-row class="mb-2">
        <b-col v-if="showSearch"
          cols="12"
          :md="hasActionButton ? 7 : 8"
          :sm="hasActionButton ? 7 : 8"
          class="d-flex align-items-center justify-content-start"
        >
          <search-with-auto-complete-vue
            v-if="useAutoCompleteSearch"
            @selected="(val) => $emit('update:searchFilter', val)"
          />
          <a-input-search
            v-else
            placeholder="Search"
            enter-button
            @search="$event => $emit('update:searchFilter', $event)"
          />
        </b-col>

        <b-col v-else-if="showFilter && showFilter"
          cols="12"
          :md="hasActionButton ? 7 : 8"
          :sm="hasActionButton ? 7 : 8"
        >
          <slot name="filters"></slot>
        </b-col>

        <b-col
          cols="12"
          :md="hasActionButton ? 5 : 4"
          :sm="hasActionButton ? 5 : 4"
          class="d-flex align-items-center justify-content-end"
        >
          <a-button
            v-if="hasFilter"
            :type="showFilter ? 'primary' : 'outline-primary'"
            @click="onToggleFilter()"
            icon="filter"
            class="mr-1"
          >
            <span class="align-middle text-lg">Filter</span>
          </a-button>

          <a-button
            v-if="showExportButton"
            type="outline-primary"
            class="mr-1"
            @click="$emit('export')"
            icon="export"
          >
            Export
          </a-button>

          <a-button
            v-if="showImportButton"
            type="outline-primary"
            class="mr-1"
            @click="onTriggerSelectImportLoanRecordsCsvFile"
            icon="import"
          >
            <b-form-file
              v-show="false"
              ref="loan_repayment_file"
              accept="text/csv"
              placeholder=""
              no-drop
              @input="onImportUserRecordsCSVFileSelected"
            />
            <span class="ml-2">Import</span>
          </a-button>

          <slot name="action_button"></slot>
        </b-col>
      </b-row>

      <div v-if="showFilter && showFilter && showSearch" class="mb-2">
        <slot name="filters"></slot>
      </div>

      <div v-if="hasLegend" class="mb-2">
        <slot name="legend"></slot>
      </div>
    </div>

    <div>
      <b-table
        ref="listTable"
        show-empty
        responsive
        primary-key="id"
        class="position-relative"
        empty-text="No matching records found"
        :no-border-collapse="false"
        :table-class="tableClass"
        :select-mode="selectionMode"
        :selectable="['multi', 'range'].includes(selectionMode)"
        :hover="true"
        :items="filteredRecords"
        :fields="columns"
        :tbody-tr-class="tbodyRowClass"
        @row-clicked="onTbRowClicked"
        @row-selected="onRowsSelected"
        head-variant="primary"
      >
        <template v-if="['multi', 'range'].includes(selectionMode)" #head(check)>
          <b-form-checkbox :disabled="!records.length" @change="onCheckOrUncheckAllRecords" />
        </template>

        <template v-if="['multi', 'range'].includes(selectionMode)" #cell(check)="{ index, rowSelected }">
          <b-form-checkbox :checked="rowSelected" @change="onCheckOrUncheckRow($event, index)" />
        </template>

        <template
          v-for="slotName in Object.keys($scopedSlots)"
          v-slot:[slotName]="scopeSlotData"
        >
          <slot :name="slotName" v-bind="scopeSlotData"></slot>
        </template>
        <template v-if="!hasActionsSlot" #cell(actions)="slotData">
          <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            <feather-icon icon="MoreHorizontalIcon" size="16" class="align-middle text-body" />
          </a>
          <template #overlay>
            <a-menu class="p-2">
              <a-menu-item
                v-for="actionItem of tableActions"
                @click="($e) => onTableActionClicked({ key: actionItem.key, ...$e, actionItem, item: slotData.item })"
                :key="actionItem.key" :id="slotData.item._id"
                :class="actionItem.menuItemClass"
              >
                <div v-if="!actionItem.confirmation" class="d-flex justify-content-center align-items-center">
                  <feather-icon :icon="actionItem.icon" />
                  <span class="align-middle ml-3">{{ actionItem.title }}</span>
                </div>
                <a-popconfirm
                  v-else-if="actionItem.confirmation"
                  :title="actionItem.confirmation.title|| 'Do you want to delete this item?'"
                  :ok-text="actionItem.confirmation.okText || 'Ok'"
                  :cancel-text="actionItem.confirmation.cancelText || 'Cancel'"
                  placement="bottom"
                  @confirm="() => onTableActionClicked({ key: actionItem.key, actionItem, confirmed: true, item: slotData.item })"
                >
                  <a class="d-flex align-items-center justify-content-center">
                    <feather-icon class="text-danger" :icon="actionItem.icon" />
                    <span class="align-middle ml-3 text-danger">{{ actionItem.title }}</span>
                  </a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2" v-if="showPagination">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ pageMeta.from }} to {{ pageMeta.to }} of {{ pageMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <a-pagination
            v-model="currentPage"
            :total="totalRecords"
            :pageSize.sync="recordsPerPage"
            :hideOnSinglePage="true"
            size="small"
            @change="$emit('update:currentPageNumber', $event)"
            show-less-items
            show-size-changer
          />
        </b-col>
      </b-row>
    </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import {
  BRow, BCol, BTable,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import SearchWithAutoCompleteVue from './SearchWithAutoComplete.vue'
import ToastificationContent from '../../toastification/ToastificationContent.vue'

export default {
  name: 'list-table',
  components: {
    BTable,
    BRow,
    BCol,
    BFormFile,
    BFormCheckbox,
    SearchWithAutoCompleteVue,
  },
  directives: {
    Ripple,
  },
  props: {
    records: {
      required: true,
      type: [Array, Function],
    },
    columns: {
      required: true,
      type: Array,
    },
    tbodyRowClass: {
      type: String,
      default: 'tb-row',
    },
    currentPageNumber: {
      type: Number,
      default: 0,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchFilter: {
      type: String,
      required: false,
      default: '',
    },
    tableClass: {
      type: String,
      default: '',
    },
    selectionMode: {
      type: String,
      default: 'single',
      validator: (value) => ['multi', 'single', 'range'].includes(value.toString()),
    },
    useAutoCompleteSearch: {
      type: Boolean,
      default: false,
    },
    showExportButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    showImportButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFilterByDefault: {
      type: Boolean,
      default: true,
      required: false,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    tableContainerClass: {
      type: String,
      required: false,
      default: 'p-5'
    },
    showAdvancedFeatures: {
      type: Boolean,
      default: true,
      required: false,
    },
    tableActions: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      search: '',
      showFilter: true,
      currentPage: this.currentPageNumber,
      selectedRows: [],
    }
  },
  computed: {
    recordsPerPage: {
      get() {
        return this.$store.getters['navigation/recordsPerPage'];
      },
      set(value) {
        this.$store.commit('navigation/UPDATE_RECORDS_PER_PAGE', value);
      },
    },
    pageMeta() {
      const itemsCount = this.records.length || 0;
      return {
        from: this.recordsPerPage * (this.currentPageNumber - 1) + (itemsCount ? 1 : 0),
        to: this.recordsPerPage * (this.currentPageNumber - 1) + itemsCount,
        of: this.totalRecords,
      }
    },
    hasFilter() {
      return Object.keys(this.$scopedSlots).includes('filters');
    },
    hasActionButton() {
      return Object.keys(this.$scopedSlots).includes('action_button');
    },
    hasLegend() {
      return Object.keys(this.$scopedSlots).includes('legend');
    },
    filteredRecords() {
      return this.records
    },
    hasActionsSlot() {
      return Object.keys(this.$scopedSlots).includes('cell(actions)')
    }
  },
  watch: {
    currentPage: {
      handler(update) {
        if (update !== this.currentPageNumber) {
          this.$emit('update:currentPageNumber', update);
        }
      },
      immediate: false,
    },
    recordsPerPage: {
      handler(update) {
        if (update !== this.recordsPerPageNumber) {
          this.$emit('update:recordsPerPageNumber', update);
        }
      },
      immediate: false,
    },
    selectedRows: {
      handler(selectedRows) {
        this.$emit('selected-rows', selectedRows)
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.showFilter = this.showFilterByDefault;

    if (this.tableActions && this.tableActions.length > 0) {
      // ensure a table column exists for actions
      const column = { key: 'actions' }
      const existsInConfig = this.columns.some((col) => col.key === column.key)

      if (!existsInConfig) {
        this.$emit('update:columns', [...this.columns, column]);
      }
    }
  },
  destroyed() {
    this.$store.commit('navigation/UPDATE_TOTAL_RECORDS', 0);
  },
  methods: {
    onTbRowClicked(rowItem) {
      this.$emit('row-clicked', rowItem)
    },
    onRowsSelected(selectedRows) {
      this.selectedRows = selectedRows;
    },
    onCheckOrUncheckAllRecords(checked) {
      if (checked) {
        this.$refs.listTable.selectAllRows()
      } else {
        this.$refs.listTable.clearSelected();
      }
    },
    onCheckOrUncheckRow(checked, rowIndex) {
      if (checked) {
        if (this.$refs.listTable.isRowSelected(rowIndex)) {
          this.$refs.listTable.unselectRow(rowIndex)
        } else {
          this.$refs.listTable.selectRow(rowIndex)
        }
      } else if (!checked && this.$refs.listTable.isRowSelected(rowIndex)) {
        this.$refs.listTable.unselectRow(rowIndex)
      }
    },
    onToggleFilter() {
      this.showFilter = !this.showFilter
    },
    onTriggerSelectImportLoanRecordsCsvFile() {
      const loan_repayment_file_ref = this.$refs.loan_repayment_file
      loan_repayment_file_ref.$el.childNodes[0].click();
    },
    onImportUserRecordsCSVFileSelected(file) {
      if (!file || !get(file, 'name').includes('.csv')) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Invalid file selected',
          },
        });
        return false;
      }

      this.$emit('import', file)
      return true;
    },
    onTableActionClicked(payload) {
      const { actionItem, confirmed = false } = payload
      if (actionItem.confirmation && !confirmed) { return }
      this.$emit('action', payload)
    }
  },
}
</script>
