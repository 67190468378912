import { isValid, format } from 'date-fns';
import { difference } from 'lodash';

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const termsValidator = val => {
  if (val === true) {
    return true
  }
  return false;
}

export const arrayValidator = (val) => {
  if (Array.isArray(val) && val.length > 0) {
    return true
  }
  return false
}

export const validateDate = (val) => {
  try {
    return isValid(new Date(format(new Date(val), 'yyyy-MM-dd')));
  } catch (_) {
    return false
  }
}

export function isValidPolicyStatement(statement) {
  const expectedKeys = ['actions', 'resource', 'effect'];
  const keys = Object.keys(statement);
  if (difference(keys, expectedKeys).length !== 0) {
    throw new Error('Not equal statement keys')
    // return false;
  }
  const allowedEffects = ['Allow', 'Deny']
  if (!allowedEffects.includes(statement.effect)) throw new Error(`Invalid effect ${statement.effect}`);
  if (typeof (statement.resource) !== 'string') throw new Error('Invalid resource specified');

  if (statement.actions.every((action) => typeof (action) === 'string' && action.trim.length > 0)) {
    throw new Error('Invalid actions provided');
  }
}

export function validatePolicy(payload) {
  if (!payload.name) throw new Error('Name is required');
  if (!payload.name.trim().length > 3) throw new Error('Name must be at least 3 characters')
  if ((!Array.isArray(payload.statements))) throw new Error('Statements not provided');
  if (payload.statements.length === 0) throw new Error('Statements must have at least one item')
  payload.statements.forEach(statement => isValidPolicyStatement(statement));
}
