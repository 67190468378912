<template>
  <nav style="background-color: white;" class="sticky-nav shadow-sm">
    <div class="container-xxl cui__utils__content py-1">
      <div class="app-header-row">
        <b-link to="/" class="d-block mr-5">
          <img src="@/core/assets/images/logo.png" width="50" height="50" />
        </b-link>

        <!-- <product-search-with-auto-complete
          class="col d-none d-sm-block"
          style="flex-grow: 2.5"
        /> -->
        <div class="col">
          <product-search-full-screen />
        </div>

        <div class="d-flex flex-row justify-content-end align-items-center top-icons">
          <!-- <product-search-full-screen class="my-3" /> -->
          <!-- <feather-icon
            icon="SearchIcon"
            class="text-dark fw-light top-icon search-icon"
            size="40"
            stroke-width="1"
            @click="showMobileSearch = !showMobileSearch"
          /> -->
          <a-button
            v-if="isLoggedIn && userRole === 'store' && !settings.isMobileView"
            @click="() => $router.push({ name: 'store-dashboard' })"
            type="primary"
            class="rounded"
            :size="settings.isMobileView ? 'default' : 'large'"
          >
            Dashboard
          </a-button>

          <a-badge :count="cartSize">
            <ShoppingKartIcon  @click="goToCart" style="cursor: pointer;" />
          </a-badge>

          <a-button
          v-if="!isLoggedIn"
            @click="() => $router.push({ name: 'general-login' })"
            type="primary"
            :size="settings.isMobileView ? 'default' : 'large'"
            class="font-weight-bold"
          >
            Login
          </a-button>
          <div class="d-flex align-items-center gap-8" v-else>
            <cui-actions />
            <cui-user-menu />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  BLink
} from 'bootstrap-vue'

import ShoppingKartIcon from '@/core/assets/svgs/ShoppingKart.svg'
import CuiUserMenu from '@/components/cleanui/layout/Topbar/UserMenu'
import CuiActions from '@/components/cleanui/layout/Topbar/Actions'
import ProductSearchFullScreen from './ProductSearchFullScreen.vue'
import ProductSearchWithAutoComplete from './ProductSearchWithAutoComplete.vue'

import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    BLink,
    CuiUserMenu,
    CuiActions,
    ShoppingKartIcon,
    ProductSearchFullScreen,
    // ProductSearchWithAutoComplete
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('auth', ['userData', 'isLoggedIn']),
    ...mapGetters('cart', ['cartSize']),
    userRole() {
      return this.getValueFromSource(this.userData, 'user_type')
    }
  },
  data() {
    return {
      showMobileMenu: false,
      showMobileSearch: false,
      current: ['mail'],
      query: '',
      userMenuItems: [
        {
          to: '/about',
          text: 'New',
          selected: false,
        },
      ],
      subRouteLinks: {
        products: [
          'client-home',
          'product-details'
        ],
        customize: [
          'customize'
        ],
        gallery: [
          'client-gallery'
        ],
      },
      selectedSubMenus: ['gallery'],
      isOpen: false,
      activeMenu: 'ant-menu-item-selected'
    };
  },
  methods: {
    goToCart() {
      this.$router.push({ name: 'shopping-cart' }).catch(() => {});
    },
    isActiveTab(key) {
      const { name } = this.$route;
      const links = this.subRouteLinks[key]

      if (links) {
        return links.includes(name)
      }
    }
  },
}
</script>

<style lang="scss">
.search-icon {
  display: none;
}
.top-icon {
  margin-right: 10px;
}
.top-icons {
  gap: 20px;
}

@media only screen and (max-width: 800px) {
  .search-bar {
    display: none;
  }
  .search-icon {
    display: block;
  }
  .logo {
    width: 150px;
    height: 150px;
  }
  .top-icon {
    width: 25px;
    margin-right: 6px;
  }
  .top-icons {
    gap: 20px;
  }
}
.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

@media only screen and (max-width: 575px) {
  .app-header-row {
    justify-content: unset !important;
    gap: 10px;
  }
}

.app-header-row {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;

  justify-content: space-between;
  height: 60px;
  flex-direction: row;
  align-items: center;
}
</style>
