<template>
  <b-overlay :show="loading">
    <div style="padding: 20px; border-radius: 5px; min-height: 459px;">
      <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 30px;">
        <h4 class="mb-0">{{ title }}</h4>
        <HorizontalScrollButton @click="horizontalScrollClickHandler" />
      </div>

      <div class="d-flex align-items-center products-grid" ref="productContainer" style="overflow-x: hidden; scroll-behavior: smooth;">
        <ProductCard
          v-for="(product, index) in featuredProducts" :key="index" :product="product" />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ProductCard from '@/components/products/ProductCard';
import HorizontalScrollButton from '@/components/products/HorizontalScrollButton';

import { mapState } from 'vuex';
import ProductMixin from '@/core/mixins/products'
import { PublicServices } from '@/services/jwt/api/public';

export default {
  components: {
    ProductCard,
    HorizontalScrollButton
  },
  mixins: [ProductMixin],
  props: {
    title: {
      type: String,
      required: true,
      default: 'Trending Products'
    }
  },
  data() {
    return {
      products: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(['settings']),
    mobileView() {
      return this.settings.isMobileView;
    },
    featuredProducts() {
      return this.products.map(this.productsTransformer);
    },
  },
  mounted() {
    this.fetchFeaturedProducts({});
  },
  methods: {
    async fetchFeaturedProducts(query) {
      try {
        this.loading = true;
        const response = await PublicServices.fetchFeaturedProducts(query);
        this.products = this.getValueFromSource(response, 'data.data');
      } catch (error) {
        console.log('ERROR', error);
      } finally {
        this.loading = false;
      }
    },
    horizontalScrollClickHandler(position) {
      const { productContainer } = this.$refs;

      const containerDimensions = productContainer.getBoundingClientRect();
      const containerWidth = containerDimensions.width;

      if (position === 'left') {
        productContainer.scrollLeft -= containerWidth;
      }

      if (position === 'right') {
        productContainer.scrollLeft += containerWidth;
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 800px) {
  .filters {
    display: none;
  }

  .products-grid {
    gap: 5px;
  }
}
</style>
