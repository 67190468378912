<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="false"
    :collapsed="false"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <vue-custom-scrollbar class="pt-4">
      <a-menu
        forceSubMenuRender
        :inlineCollapsed="settings.isMobileView ? false : settings.isMenuCollapsed"
        :mode="'inline'"
        :selectedKeys="selectedKeys"
        :openKeys.sync="openKeys"
        @click="handleClick"
        @openChange="handleOpenChange"
        :inlineIndent="15"
        :class="$style.navigation"
        style="flex-basis: fit-content"
      >
        <template v-for="(item, index) in menuData">
          <template>
            <a-menu-item-group :key="index" v-if="item.category">
              <template slot="title">{{ item.title }}</template>
            </a-menu-item-group>
            <item
              v-if="!item.children && !item.category"
              :menu-info="item"
              :styles="$style"
              :key="item.key"
            />
            <sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key" />
          </template>
        </template>
      </a-menu>
      </vue-custom-scrollbar>
  </a-layout-sider>
</template>

<script>
import store from 'store'
import find from 'lodash/find'
import Item from './partials/item'
import SubMenu from './partials/submenu'
import vueCustomScrollbar from 'vue-custom-scrollbar'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'account-menu-left',
  components: { vueCustomScrollbar, SubMenu, Item },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
    ...mapGetters('auth', ['userData']),
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
  },
  data() {
    return {
      selectedKeys: [],
      openKeys: [],
      menuData: [
        {
          title: 'My Profile',
          key: 'my-profile',
          url: '/account/profile',
          icon: 'UserIcon'
        },
        {
          title: 'Orders',
          key: 'my-orders',
          url: '/account/orders',
          icon: 'ClipboardIcon'
        },
        {
          title: 'Bank Payments',
          key: 'account-bank-payments',
          url: '/bank-payments',
          icon: 'DollarSignIcon'
        },
        {
          title: 'Recently Viewed',
          key: 'recently-viewed',
          url: '/account/viewed/recently',
          icon: 'ClockIcon'
        },
        {
          title: 'My Notifications',
          key: 'notifications-list',
          url: '/notifications',
          icon: 'BellIcon'
        },
      ]
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    '$route'() {
      this.setSelectedKeys()
    },
  },
  methods: {
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    },
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
