import apiClient from '@/services/axios';
import { jwtConfig } from '@/services/jwt/jwtConfig';

export const SearchServices = {
  fetch: (type, params) => {
    return apiClient.get(`${jwtConfig.search.root}/${type}/autocomplete`, { params })
  },
  fetchStores: (type, params) => {
    return apiClient.get(`${jwtConfig.store.root}/search/${type}/autocomplete`, { params })
  },
}
