<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div class="card product-card shadow-none border-0">
          <div class="product-image">
            <b-skeleton-img no-aspect class="product-thumb"  />
          </div>
          <b-skeleton width="50%" class="my-1" type="icon"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>
      </template>

      <div class="card product-card shadow-none border-0">
        <div class="product-image">
          <img
            alt=""
            :src="product.src"
            class="product-thumb"
            :id="`${product._id}-${product.src}`"
            @click="goToSingleProduct(product)"
          />
        </div>

        <div class="product-info">
          <span class="d-flex justify-content-between align-items-center">
            <span class="official-store-tag" :title="product.store_name">{{ product.store_name }}</span>
          </span>

          <div
            class="d-flex flex-column justify-content-between align-products-center pt-2 mr-2"
            @click="goToSingleProduct(product)"
          >
            <div class="product-brand pl-2 -ellipsis-1" :title="product.label">{{ product.label }}</div>
          </div>

          <div class="d-flex flex-wrap align-items-center justify-content-evenly text-dark fw-semibold pl-2 pt-2">
            <span class="d-inline-block mr-2">
              {{ product.price }}
            </span>

            <span
              class="d-inline-block text-muted"
              style="text-decoration: line-through;"
              v-if="product.has_compare_price"
            >
              {{ product.compare_price }}
            </span>

            <span
              class="discount-display"
              v-if="product.discount > 0"
            >
              -{{ product.discount }}%
            </span>
          </div>

          <span
            v-if="!product.variants.length && trackProductInventory"
            class="d-inline-block d-flex align-items-center mt-1 ml-2"
            :class="{
              'text-danger': product.inventories.reduce((a, c) => a + c.quantity, 0) === 0,
              'text-warning': product.inventories.reduce((a, c) => a + c.quantity, 0) > 0,
            }"
            style="font-size: 12px;"
          >
            <a-icon type="info-circle" class="mr-1"/>
            ({{ product.inventories.reduce((a, c) => a + c.quantity, 0) }} units left)
          </span>

          <span
            v-if="product.variants.length > 0"
            class="d-inline-block d-flex align-items-center mt-1 ml-2 text-info"
            style="font-size: 12px;"
          >
            <a-icon type="info-circle" class="mr-1"/>
            Has options
          </span>

          <!-- make this standalone component-->
          <span
            v-if="!hasVariations && productCartItem"
            class="rounded text-white py-2 card-btn-actions absolute-btn btn btn-primary d-inline-block w-100">
            <span v-if="productCartItem">
              <feather-icon
                size="21"
                class="product-quantity-controls"
                icon="MinusCircleIcon"
                @click="decreaseProductQuantity"
              />

              <span class="mx-5">{{ numberOfProductVariationsInCart }}</span>

              <feather-icon
                v-if="!trackProductInventory || showIncreaseCartQuantityButton"
                size="21"
                class="product-quantity-controls"
                icon="PlusCircleIcon"
                @click="increaseProductQuantity"
              />
            </span>
          </span>

          <span
            v-else-if="product.variants.length > 0"
            class="rounded py-2 px-4 d-inline-block text-center card-btn absolute-btn bg-primary text-white"
            @click="addProductToCart"
          >
            <span>
              <span v-if="numberOfProductVariationsInCart > 0">
                ( {{ numberOfProductVariationsInCart }} ) In cart
              </span>

              <span v-else>
                <em class="fe fe-shopping-cart mr-1" />
                Add to cart
              </span>
            </span>
          </span>

          <span
            v-else
            :class="{
              'bg-primary': productIsInStock,
              'text-white': productIsInStock,
              'cursor-pointer': productIsInStock,

              'bg-disabled': productIsOutOfStock,
              'text-danger': productIsOutOfStock,
              'cursor-not-allowed': productIsOutOfStock
            }"
            class="rounded py-2 px-4 d-inline-block text-center card-btn absolute-btn"
            @click="addProductToCart"
          >
            <span v-if="productIsInStock">
              <span v-if="numberOfProductVariationsInCart > 0">
                ( {{ numberOfProductVariationsInCart }} ) In cart
              </span>

              <span v-else>
                <em class="fe fe-shopping-cart mr-1" />
                Add to cart
              </span>
            </span>

            <span
              v-if="productIsOutOfStock"
              class="text-center w-100"
              style="font-weight: 200;"
            >
              Out of stock
            </span>
          </span>
        </div>
      </div>
    </b-skeleton-wrapper>

    <a-modal
      title="Add to cart"
      :visible="showSelectVariationsModal && !settings.isMobileView"
      @cancel="() => showSelectVariationsModal = false"
      :footer="null"
    >
      <template slot="closeIcon">
        <feather-icon icon="XIcon" size="20" />
      </template>

      <add-to-cart
        ref="showSelectVariationsModal"
        :product="product"
        @close="() => showSelectVariationsModal = false"
      />
    </a-modal>

    <a-drawer
      height="top"
      placement="bottom"
      :closable="false"
      wrapClassName="bottom-drawer"
      :visible="showSelectVariationsModal && settings.isMobileView"
      @close="() => showSelectVariationsModal = false"
    >
      <hr class="modal-bar">
      <h4 class="mt-4">Product type</h4><hr class="mb-5">
      <add-to-cart
        ref="showSelectVariationsModal"
        :productVariations="product.variants"
        :product="product"
        @close="() => showSelectVariationsModal = false"
      />
    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BSkeleton, BSkeletonWrapper, BSkeletonImg } from 'bootstrap-vue';

import AddToCart from './AddToCart.vue';
import ProductMixin from '@/core/mixins/products';

export default {
  name: 'ProductCard',
  mixins: [ProductMixin],
  components: {
    AddToCart,
    BSkeleton,
    BSkeletonWrapper,
    BSkeletonImg,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  data() {
    return {
      offset: 0,
      showSelectVariationsModal: false,
      loading: true
    };
  },
  mounted() {
    const node = document.createElement("img")
    node.onload = () => {
      this.loading = false;
    }
    node.src = this.product.src
  },
  computed: {
    ...mapState(['settings']),
    hasVariations() {
      return this.getValueFromSource(this.product, 'variants', []).length > 0
    },
    productCartItem() {
      if (this.hasVariations) { return null }
      return this.getOrderByQuery({ product_id: this.product._id })
    },
    productIsInCart() {
      if (!this.hasVariations && this.productCartItem) { return true }

      const orderInCart = this.getOrderByQuery({ product_id: this.product._id })
      return !!orderInCart;
    },
    numberOfProductVariationsInCart() {
      if (!this.hasVariations && this.productCartItem) {
        return this.productCartItem.quantity
      }

      let quantity = 0;

      const cartItems = this.queryOrdersInCart({ product_id: this.product._id });
      for (const cartItem of cartItems) {
        quantity += cartItem.quantity
      }

      return quantity;
    },
    showIncreaseCartQuantityButton(){
      const orderInCart = this.productCartItem
      if (orderInCart){
        const track_quantity = this.getValueFromSource(this.product, 'inventory_config.track_quantity', false);
        if (track_quantity && orderInCart.quantity < this.product.inventories.reduce((a, c) => a + c.quantity, 0)){
          return true;
        }
      }

      return false
    },
    productInventoryTotal() {
      return this.getValueFromSource(this.product, 'inventories', []).reduce((a, c) => a + c.quantity, 0)
    },
    productHasInventory() {
      return this.productInventoryTotal > 0
    },
    allowOutOfStockSales() {
      return this.getValueFromSource(this.product, 'inventory_config.allow_out_of_stock_sales', false)
    },
    trackProductInventory() {
      return  this.getValueFromSource(this.product, 'inventory_config.track_quantity', false);
    },
    productIsInStock() {
      if (!this.trackProductInventory){
        return true;
      }

      if (this.trackProductInventory){
        if (this.productInventoryTotal > 0){
          return true;
        }

        if (this.productInventoryTotal < 1 && this.allowOutOfStockSales){
          return true
        }
      }

      return false;
    },
    productIsOutOfStock() {
      if (this.trackProductInventory){
        if (this.productInventoryTotal < 1 && !this.allowOutOfStockSales){
          return true
        }
      }

      return false
    }
  },
  methods: {
    increaseProductQuantity() {
      this.$store.commit('cart/INCREASE_QUANTITY', this.productCartItem.id)
    },
    decreaseProductQuantity() {
      this.$store.commit('cart/DECREASE_QUANTITY', this.productCartItem.id)
    },
    goToSingleProduct(product) {
      this.$router.push({ name: 'product-details', params: { id: product._id } }).catch(() => {});
    },
    addProductToCart() {
      const product = this.product;
      const variants = this.getValueFromSource(product, 'variants', [])
      if (!variants || variants.length === 0) {
        const track_quantity = this.trackProductInventory;
        if (track_quantity && product.inventories.reduce((a, c) => a + c.quantity, 0) === 0){
          return;
        }

        return this.$store.commit('cart/ADD_TO_CART', {
          quantity: 1,
          variation_title: ' - ',
          product_id: product._id,
          product_title: product.label,
          product_image_url: product.src,
          price: product.base_price || 0,
        })
      }
      this.showSelectVariationsModal = true;
    },
  },
};
</script>

<style scoped>
.discount-display {
  color: #f68b1e;
  background-color: #fef3e9;
  font-size: 12px;
  display: inline-block;
}
/* PRODUCTS */
.product {
  position: relative;
}

.product-category {
  font-size: 2vw;
  font-weight: 500;
  margin: 0 auto 40px;
  font-family: "Lato", sans-serif;
  text-transform: capitalize;
}

.product-container {
  padding: 0.5rem 1vw 2rem 1vw;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: auto;
}

.product-container::-webkit-scrollbar {
  display: none;
}

.product-card {
  flex: 0 0 auto;
  width: 190px;
  cursor: pointer;
  height: 370px;
  margin-bottom: 1.015rem;
  position: relative;
}

.product-card {
  margin-right: 5px;
}

.product-card:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12) !important;
}

.product-image {
  position: relative;
  width: 185px;
  height: 190px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.product-thumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 20px;
}

.heart-tag {
  position: absolute;
  background: rgba(255, 255, 255, 0);
  padding: 5px;
  border-radius: 5px;
  color: #056832;
  right: 10px;
  top: 10px;
  text-transform: capitalize;
}

.heart-icon {
  color: #323436;
}

.absolute-btn {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.card-btn {
  opacity: 0;
  transition: opacity 0.5s;
}

.card-btn-actions {
  padding: 5px;
  width: 90%;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  align-self: center;
}

.product-card:hover .card-btn {
  opacity: 1;
}

.product-info {
  width: 100%;
  height: 100px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

.product-brand {
  font-family: "Lato", sans-serif;
  color: #3e4143;
}

.dot {
  background-color: #eee;
  border: 1px solid #000000;
  border-radius: 10px;
  display: inline-block;
  height: 15px;
  width: 15px;
}

.arrow-nav {
  display: block;
  margin-right: 2.5vw;
  padding: 0.5rem 1vw 2rem 1vw;
}

.official-store-tag {
  font-family: 'Lato';
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #FFFFFF;
  background-color: #607821;
  border-radius: 5px;
  max-width: 80px;
  height: 22px;
  margin-left: 0.5rem;

  /*elipsis*/
  white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

@media only screen and (max-width: 800px) {
  .card-btn {
    opacity: 1;
  }
  .arrow-nav {
    display: none;
  }

  .product-image {
    width: 175px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .product-thumb {
    width: 100%;
    height: 100%;
    /* padding: 20px; */
  }

  .product-card {
    width: 160px;
    height: 340px;
    margin-right: auto;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .price {
    font-size: 3vw;
  }
}
</style>
