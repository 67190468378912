import Vue from 'vue'
import Vuex from 'vuex'
import { get } from 'lodash'

import { PublicServices } from '@/services/jwt/api/public'

const { VUE_APP_COUNTRY_CODE } = process.env

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    currency: {},
    currencies: []
  },
  mutations: {
    SET_CURRENCY(state, payload) {
      state.currency = payload
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload
    },
  },
  actions: {
    async FETCH_CURRENCY({ commit }) {
      const response = await PublicServices.fetchCurrency({ country_code: VUE_APP_COUNTRY_CODE })
      commit('SET_CURRENCY', get(response, 'data.data'))
    },
  },
  getters: {
    currency_symbol: state => get(state, 'currency.currency_symbol'),
  },
}
