import router from '@router'

// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from 'vue'
import { format, formatDistance } from 'date-fns'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export function setDataName($event) {
  const val = $event.target.value;
  const data_name_value = val.trim().toLowerCase().replaceAll(' ', '_').replaceAll(/[$&+,:;=?@#"'`~|/<>.^*()%!-]/g, '')
  return this.fieldsForm.setFieldsValue({ data_name: data_name_value })
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const maskPhoneNumber = (phoneNumber) => {
  const masked = phoneNumber.replace(/[\w\W]/g, '*');
  return `${masked.substr(0, phoneNumber.length - 4)}${phoneNumber.substr(phoneNumber.length - 4)}`;
}

export const formatDate = (date, formatStr = 'dd-MM-yyyy') => {
  if (date) {
    return format(new Date(date), formatStr);
  }
}

export const formatDateDistance = (date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}
export const formatMoney = (
  amount,
  fraction = 2,
) => {
  if (!amount) {
    return 0.00
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: fraction,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

export const calculatePercentage = (percent, value) => {
  return (percent / 100) * value
}

export function base64toBlob(data) {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  // eslint-disable-next-line prefer-destructuring
  let length = bytes.length;
  const out = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  while (length--) {
      out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
}
