import apiClient from '@/services/axios';
import { jwtConfig } from '@/services/jwt/jwtConfig';
import { StorageService } from '@/services/jwt/storageService';

export const AuthJwtServices = {

  login: (...args) => {
    return apiClient.post(jwtConfig.auth.loginEndpoint, ...args);
  },

  logout: (...args) => {
    return apiClient.post(jwtConfig.auth.logoutEndpoint);
  },

  logPageAccess: (payload = {}) => {
    return apiClient.post(jwtConfig.auth.logsEndpoint);
  },

  confirmTokenValidity: (tokenType) => {
    let token = null;

    if (tokenType === 'web_token') {
      StorageService.clearPasswordResetToken();
      StorageService.clearAccountConfirmationToken();

      token = StorageService.getAccessToken();
    } else if (tokenType === 'password_reset_token') {
      StorageService.clearAccessToken();
      StorageService.clearAccountConfirmationToken();

      token = StorageService.getPasswordResetToken();
    } else if (tokenType === 'confirm_account_token') {
      StorageService.clearAccessToken();
      StorageService.clearPasswordResetToken();

      token = StorageService.getAccountConfirmationToken()
    }

    return apiClient.post(jwtConfig.auth.confirmTokenValidityEndpoint, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },

  confirmAccount: () => {
    StorageService.clearAccessToken();
    StorageService.clearPasswordResetToken();

    return apiClient.post(jwtConfig.auth.confirmEmailEndpoint, {}, {
      headers: {
        Authorization: `Bearer ${StorageService.getAccountConfirmationToken()}`,
      },
    })
  },

  register: (formData) => {
    return apiClient.post(jwtConfig.auth.registerEndpoint, formData)
  },

  resendAccountConfirmationEmail: (payload) => {
    return apiClient.post(jwtConfig.auth.resendConfirmEmailEndpoint, payload)
  },

  requestPasswordReset: (...args) => {
    return apiClient.post(jwtConfig.auth.requestPasswordResetEndpoint, ...args);
  },

  resetPassword: (...args) => {
    StorageService.clearAccessToken();
    StorageService.clearAccountConfirmationToken();

    return apiClient.post(jwtConfig.auth.resetPasswordEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${StorageService.getPasswordResetToken()}`,
      },
    });
  },

  verifyMfaPasswordResetRequest(...args) {
    return apiClient.post(jwtConfig.mfa.verifyMFAPasswordResetRequestEndpoint, ...args,
      {
        headers: {
          Authorization: `Bearer ${StorageService.getMFAPasswordResetToken()}`,
        },
      })
  },

  changePassword(payload) {
    return apiClient.post(jwtConfig.auth.changePasswordEndpoint, payload);
  },

  requestPhoneVerification: (...args) => {
    StorageService.clearPasswordResetToken();
    StorageService.clearAccountConfirmationToken();

    return apiClient.post(jwtConfig.auth.requestPhoneVerificationEndpoint, ...args)
  },

  confirmPhoneVerification: (...args) => {
    StorageService.clearPasswordResetToken();
    StorageService.clearAccountConfirmationToken();

    return apiClient.post(jwtConfig.auth.confirmPhoneEndpoint, ...args, {
      headers: {
        Authorization: `Bearer ${StorageService.getPhoneVerificationToken()}`,
      },
    })
  },

  completeRegistration: (formData) => {
    return apiClient.post(jwtConfig.auth.completeOnboardEndpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  updateProfile: (payload = {}) => {
    return apiClient.put(jwtConfig.auth.updateUserProfileEndpoint, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

}
