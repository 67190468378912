<template>
  <a-menu-item v-if="canViewNavMenuItem(menuInfo)" :key="menuInfo.key" v-bind="$props" v-on="$listeners" :disabled="menuInfo.disabled">
    <router-link v-if="menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <!-- <span class="material-symbols-rounded icon">{{menuInfo.icon}}</span> -->
      <a-icon class="icon" :type="menuInfo.icon" />
    </router-link>
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <a-icon class="icon" :type="menuInfo.icon" />
      <!-- <span class="material-symbols-rounded icon">{{menuInfo.icon}}</span> -->
    </span>
  </a-menu-item>
</template>
<script>
import { Menu } from 'ant-design-vue'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: Object,
    styles: Object,
  },
}
</script>

<style scoped lang="scss">
@import '@/components/mixins.scss';

.icon {
  text-align: center;
  position: absolute;
  right: 14px;
  width: 20px;
  top: 11px;
  margin: 0 !important;
  line-height: 1 !important;
  color: $gray-5;
  font-size: rem(18);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

</style>
