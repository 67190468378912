import apiClient from '@/services/axios';
import { jwtConfig } from '@/services/jwt/jwtConfig';

export const StoreServices = {
  // Account
  updateStore(data) {
    return apiClient.put(`${jwtConfig.store.root}/account`, data);
  },
  transferStoreOwnerShip(payload) {
    return apiClient.post(`${jwtConfig.store.root}/account/stores/ownership/transfer`, payload);
  },
  addStoreUser(payload) {
    return apiClient.post(`${jwtConfig.store.root}/account/stores/users`, payload);
  },
  updateStoreUser(id, payload) {
    return apiClient.put(`${jwtConfig.store.root}/account/stores/users/${id}`, payload);
  },
  deleteStoreUser(id) {
    return apiClient.delete(`${jwtConfig.store.root}/account/stores/users/${id}`);
  },
  fetchStoreUsers() {
    return apiClient.get(`${jwtConfig.store.root}/account/stores/users`);
  },
  fetchOwnStores() {
    return apiClient.get(`${jwtConfig.store.root}/account/stores`);
  },
  changeCurrentStore(payload) {
    return apiClient.post(`${jwtConfig.store.root}/account/stores/current`, payload);
  },
  fetchCouponForOrder: (coupon_code) => {
    return apiClient.get(`${jwtConfig.store.orders}/coupons/${coupon_code}`)
  },

  // Fetch Reports
  fetchReport(params) {
    return apiClient.get(`/sales/report`, { params });
  },
  exportReport(params) {
    return apiClient.get(`/sales/report/export`, { params });
  },
  downLoadCustomReport(params) {
    return apiClient.get(`/sales/report/custom`, { params });
  },
  downLoadCustomReportPDF(params) {
    return apiClient.get(`/sales/report/custom-pdf`, { params });
  },

  // Store Locations
  createStoreLocation(data) {
    return apiClient.post(`${jwtConfig.store.locations}`, data);
  },
  updateStoreLocation(id, data) {
    return apiClient.put(`${jwtConfig.store.locations}/${id}`, data);
  },
  getStoreLocation(id) {
    return apiClient.get(`${jwtConfig.store.locations}/${id}`);
  },
  fetchStoreLocations(params) {
    return apiClient.get(jwtConfig.store.locations, { params });
  },
  deactivateStoreLocation(id) {
    return apiClient.post(`${jwtConfig.store.locations}/${id}/deactivate`);
  },
  makeDefaultStoreLocation(id) {
    return apiClient.post(`${jwtConfig.store.locations}/${id}/make-default`);
  },
  changeLocationsPriority(data) {
    return apiClient.post(`${jwtConfig.store.locations}/priorities`, data);
  },

  // Products
  createProduct(payload) {
    return apiClient.post(jwtConfig.store.products, payload);
  },
  updateProduct(id, data) {
    return apiClient.put(`${jwtConfig.store.products}/${id}`, data);
  },
  deleteProduct(id, data) {
    return apiClient.delete(`${jwtConfig.store.products}/${id}`, data);
  },
  cloneProduct(id) {
    return apiClient.post(`${jwtConfig.store.products}/${id}/clone`);
  },

  fetchProducts(params) {
    return apiClient.get(jwtConfig.store.products, { params });
  },
  getProduct(id) {
    return apiClient.get(`${jwtConfig.store.products}/${id}`);
  },
  fetchProductInventoryAdjustmentHistory(id, params) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/inventory-history`, { params });
  },

  fetchProductOptions(id) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/options`);
  },
  createProductOption(id, payload) {
    return apiClient.post(`${jwtConfig.store.products}/${id}/options`, payload);
  },
  updateProductOption(id, option_id, payload) {
    return apiClient.put(`${jwtConfig.store.products}/${id}/options/${option_id}`, payload);
  },
  deleteProductOption(id, option_id) {
    return apiClient.delete(`${jwtConfig.store.products}/${id}/options/${option_id}`);
  },
  deleteProductOptionValue(id, option_id, value_id) {
    return apiClient.delete(`${jwtConfig.store.products}/${id}/options/${option_id}/values/${value_id}`);
  },

  fetchProductInventories(id) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/inventories`);
  },
  updateProductInventory(id, inventory_id, data) {
    return apiClient.put(`${jwtConfig.store.products}/${id}/inventories/${inventory_id}`, data);
  },

  fetchProductVariants(id) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/variants`);
  },
  fetchProductVariant(id, variant_id) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/variants/${variant_id}`);
  },
  updateProductVariant(id, variant_id, data) {
    return apiClient.put(`${jwtConfig.store.products}/${id}/variants/${variant_id}`, data);
  },
  fetchVariantInventoryAdjustmentHistory(id, variant_id, params) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/variants/${variant_id}/inventory-history`, { params });
  },

  // Bulk Updates
  bulkUpdateProductPrices(payload) {
    return apiClient.put(`${jwtConfig.store.products}/bulk-update/prices`, payload);
  },
  bulkUpdateProductBarCodes(payload) {
    return apiClient.put(`${jwtConfig.store.products}/bulk-update/barcodes`, payload);
  },
  bulkUpdateProductStockKeepingUnits(payload) {
    return apiClient.put(`${jwtConfig.store.products}/bulk-update/skus`, payload);
  },

  // Variant Inventories
  fetchProductVariantInventoriesByLocation(id, location_id, params) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/locations/${location_id}/variant-inventories`, { params });
  },
  bulkUpdateProductVariantsInventory(payload) {
    return apiClient.put(`${jwtConfig.store.products}/bulk-update/variants-inventory`, payload);
  },

  removeProductMedia(id, media_id) {
    return apiClient.delete(`${jwtConfig.store.products}/${id}/media/${media_id}/remove`)
  },

  fetchProductVariantInventories(id, variant_id) {
    return apiClient.get(`${jwtConfig.store.products}/${id}/variants/${variant_id}/inventories`);
  },
  updateProductVariantInventory(id, variant_id, inventory_id, data) {
    return apiClient.put(`${jwtConfig.store.products}/${id}/variants/${variant_id}/inventories/${inventory_id}`, data);
  },
  createOrder: (payload) => {
    return apiClient.post(`${jwtConfig.store.orders}`, payload)
  },
  fetchOrders(params) {
    return apiClient.get(`${jwtConfig.store.orders}`, { params })
  },
  fetchOrder(id) {
    return apiClient.get(`${jwtConfig.store.orders}/${id}`)
  },
  cancelOrder(id) {
    return apiClient.get(`${jwtConfig.store.orders}/${id}/cancel`)
  },
  attachInventoryToOrder(id, payload) {
    return apiClient.put(`${jwtConfig.store.orders}/${id}/inventory`, payload);
  },
  markOrderAsDelivered(id, payload) {
    return apiClient.put(`${jwtConfig.store.orders}/${id}/delivered`, payload);
  },
  markOrderAsOutForDelivery(id, payload) {
    return apiClient.put(`${jwtConfig.store.orders}/${id}/out-for-delivery`, payload);
  },

  // DASHBOARD STATS
  fetchDashboardStats() {
    return apiClient.get(`${jwtConfig.store.dashboard}/stats`)
  },
  fetchStockStats(params) {
    return apiClient.get(`${jwtConfig.store.dashboard}/stocks/stats`, { params })
  },
  fetchTopProductStats(params) {
    return apiClient.get(`${jwtConfig.store.dashboard}/top-products/stats`, { params })
  },
  fetchTopCustomerStats(params) {
    return apiClient.get(`${jwtConfig.store.dashboard}/top-customers/stats`, { params })
  },
  fetchOrderFrequency(params) {
    return apiClient.get(`${jwtConfig.store.dashboard}/orders/frequency/stats`, { params })
  },

  // COUPONS
  fetchCoupons(params) {
    return apiClient.get(`${jwtConfig.store.root}/coupons`, { params })
  },
  createCoupon(payload) {
    return apiClient.post(`${jwtConfig.store.root}/coupons`, payload)
  },
  updateCoupon: (id, update) => {
    return apiClient.put(`${jwtConfig.store.root}/coupons/${id}`, update)
  },
  deleteCoupon: (id) => {
    return apiClient.delete(`${jwtConfig.store.root}/coupons/${id}`)
  },
  fetchCouponForOrder: (coupon_code) => {
    return apiClient.get(`${jwtConfig.store.root}/coupons/coupon-code/${coupon_code}`)
  },
  updateCouponStatus: (id, update) => {
    return apiClient.put(`${jwtConfig.store.root}/coupons/${id}/status`, update)
  },
}
