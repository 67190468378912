import { get, startCase, trimEnd, truncate } from 'lodash';
import { mapGetters, mapState } from 'vuex';

const lodash_methods = {
  _get: get,
}

export default {
  data() {
    return {}
  },
  methods: {
    ...lodash_methods,
    getVariationTitle(variation) {
      return trimEnd(this.getValueFromSource(variation, 'key', 'N/A'), ' |')
    },
    getCartItemImage(cartItem) {
      if (cartItem.variation_image_url) {
        return cartItem.variation_image_url
      }

      if (cartItem.product_image_url) {
        return cartItem.product_image_url
      }
      return ''
    },
    productsTransformer(product) {
      const pricing = product.pricing
      return {
        favorite: false,
        _id: product._id,
        variants: product.variants,
        inventories: product.inventories,
        base_price: product.pricing.price,
        src: this.getProductImage(product),
        inventory_config: product.inventory_config,
        discount: this.getProductDiscount(product),
        has_compare_price: pricing.compare_price > 0,
        price: this.getProductPriceRangeLabel(product),
        label: this.getValueFromSource(product, 'basic.title'),
        description: this.getValueFromSource(product, 'basic.description'),
        compare_price: this.getProductPriceRangeLabel(product, 'compare_price'),
        product_category: this.getValueFromSource(product, 'product_category.title'),
        store_name: truncate(startCase(this.getValueFromSource(product, 'store.store_name', 'Official Store')), 14),
      };
    },
    getProductPriceRangeLabel(product, key = 'price') {
      const { pricing, variants } = product;
      const prices = variants.map((variant) => variant.pricing[key]).filter(price => price > 0);

      if (prices.length === 0) {
        const base_price = pricing[key] || 0
        return `GH₵ ${base_price.toFixed(2)}`;
      }

      const min = Math.min(...prices) || 0;
      const max = Math.max(...prices) || 0;

      if (min === max) {
        return `GH₵ ${max.toFixed(2)}`
      }

      return `GH₵ ${min.toFixed(2)} - GH₵ ${max.toFixed(2)}`;
    },
    getProductDiscount(product) {
      const { pricing, variants } = product;
      const prices = variants.map((variant) => variant.pricing.price)
      const compare_prices = variants.map((variant) => variant.pricing.compare_price)

      if (prices.length === 0) {
        const base_price = pricing.price || 0
        const compare_price = pricing.compare_price || 0;

        if (compare_price > base_price) {
          return (((compare_price - base_price) / compare_price) * 100).toFixed(2)
        }
      }

      const min_price = Math.min(...prices) || 0;
      const max_compare_price = Math.max(...compare_prices) || 0;

      if (max_compare_price > min_price) {
        return (((max_compare_price - min_price) / max_compare_price) * 100).toFixed(2)
      }

      return 0;
    },
    getProductImage(product) {
      const { media, variants } = product;
      const media_file = media.find((media_file) => !!media_file.path)
      if (media_file) { return media_file.path }

      for (const variant of variants) {
        const { media } = variant;

        if (media && media.path) {
          return media.path;
        }
      }
      return '';
    },
    decreaseProductQuantity(id) {
      this.$store.commit("cart/DECREASE_QUANTITY", id);
    },
    increaseProductQuantity(id) {
      this.$store.commit("cart/INCREASE_QUANTITY", id);
    },
    removeFromCart(id) {
      this.$store.commit('cart/REMOVE_FROM_CART', id)
    }
  },
  computed: {
    ...mapState(['settings', 'cart']),
    ...mapGetters('cart', ['getOrderByVariationId', 'getOrderByQuery', 'getOrderQuantity', 'getOrderById', 'queryOrdersInCart'])
  }
}
