<template>
  <div class="d-inline-block mr-4" @keydown.esc="closeSearch()">
    <a-input
      :class="$style.extInput"
      :placeholder="$t('topBar.typeToSearch')"
      style="width: 200px;"
      @focus="openSearch"
      ref="outerInput"
    >
      <span slot="prefix">
        <a-icon type="search" style="color: rgba(0,0,0,.25); font-size: 18px" />
      </span>
    </a-input>

    <div :class="[$style.livesearch, showSearch ? $style.livesearchVisible : '']">
      <button @click="closeSearch" :class="$style.close" type="button">
        <i class="icmn-cross"></i>
      </button>

      <div class="container-fluid">
        <div :class="$style.wrapper">
          <input
            v-model="searchText"
            :class="$style.searchInput"
            :placeholder="$t('topBar.typeToSearch')"
            ref="innerInput"
          />

          <div v-if="!searchText" :class="$style.results">
            <div :class="$style.resultsTitle">
              <span>Start typing</span>
            </div>
          </div>

          <div v-if="searchText" :class="$style.results">
            <div :class="$style.resultsTitle">
              <span class="d-block">Pages Search Results</span>

              <span  class="text-warning" v-if="showSearchInfo">
                Enter at-least 3 characters to search
              </span>
            </div>

            <div class="">
              <div
                v-for="(product, i) in searchResults" :class="$style.resultContent"
                :key="i"
                class="cursor-pointer"
                @click="goToProductDetails(product)"
              >
                <div
                  :class="$style.resultThumb"
                  :style="{backgroundImage: 'url(\'resources/images/content/photos/1.jpeg\')'}"
                >#{{ i + 1 }}</div>

                <div :class="$style.result">
                  <div :class="$style.resultText">
                    {{ product.basic.title }}
                  </div>
                  <div :class="[$style.resultSource, '-ellipsis-1']">{{ extractTextContentFromHTML(product.basic.description) }}</div>
                </div>
              </div>

              <div v-if="searchText.length > 2 && !searchResults.length && !loading" :class="$style.results">
                <div :class="$style.resultsTitle">
                  <span>No Results Found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, debounce } from 'lodash'
// import { BOverlay } from 'bootstrap-vue';
import { PublicServices } from '@/services/jwt/api/public';

export default {
  components: {
    // BOverlay
  },
  data() {
    return {
      loading: true,
      searchText: '',
      showSearch: false,

      showSearchInfo: false,

      searchResults: [],
      debounceInitiateSearch: null,
    }
  },
  watch: {
    searchText: {
      handler() {
        this.debounceInitiateSearch()
      },
      immediate: false,
    },
  },
  created() {
    this.debounceInitiateSearch = debounce(this.fetchSearchData, 500)
  },
  methods: {
    async fetchSearchData() {
      try {
        this.loading = true;

        if (!this.showSearch || this.searchText.length < 3) {
          this.showSearchInfo  = true
          return;
        }

        this.showSearchInfo = false;

        const response = await PublicServices.searchProducts({
          search: this.searchText
        });

        const results = this.getValueFromSource(response, 'data.data');
        this.searchResults = results
      } catch (error) {
        const error_message = get(error, 'response.data.message') || error.message
        this.$notification.error({
          title: 'Error!',
          message: error_message,
        });
      } finally {
        this.loading = false;
      }
    },
    openSearch() {
      this.showSearch = true

      setTimeout(() => {
        this.$refs.innerInput.focus()
      }, 100)
    },
    closeSearch() {
      this.showSearch = false
    },
    goToProductDetails(product){
      this.closeSearch();
      this.$router.push({ name: 'product-details', params: { id: product._id } })
      .catch(() => {})
    }
  },
}
</script>

<style lang="scss" module>
  @import '@/components/mixins.scss';

  .livesearch {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 12000;
    opacity: 0;
    visibility: hidden;
    @include transition-slow;
  }

  .livesearchVisible {
    opacity: 1;
    visibility: visible;
  }

  .extInput {
    input {
      background-color: $gray-1;
    }

    @media screen and (max-width: 620px) {
      width: 30px !important;
      input {
        width: 100px !important;
        // padding-left: 22px !important;
      }
      :global(.ant-input-prefix) {
        left: 11px !important;
        pointer-events: none;
        color: black;
      }
    }
  }

  .close {
    font-size: rem(12);
    color: $default;
    position: absolute;
    top: rem(30);
    right: rem(30);
    cursor: pointer;
    z-index: 1;
    border: none;
    background-color: transparent;
    @include transition-slow;

    &:hover {
      color: $text;
    }
  }

  .wrapper {
    padding-top: rem(60);
    padding-left: rem(65);
    padding-right: rem(15);
    line-height: 1.7;

    @media screen and (max-width: $sm-max-width) {
      padding-left: rem(20);
      padding-right: rem(20);
    }
  }

  .logoContainer {
    margin-top: rem(20);
    margin-bottom: rem(30);
  }

  .logo {
    height: rem(45);
  }

  .searchInput {
    width: 100%;
    border: none;
    font-size: rem(64);
    background-color: transparent;
    font-weight: bold;
    padding: 0;
    margin-bottom: rem(20);

    @media screen and (max-width: $sm-max-width) {
      font-size: rem(40);
    }
  }

  .options {
    padding: 0;
    margin: 0;
    margin-bottom: rem(30);
    list-style: none;
  }

  .option {
    display: inline-block;
    margin-right: rem(26);
    font-size: rem(13);
    color: $text;

    &:last-child {
      margin-right: 0;
    }
  }

  .suggestion {
    margin-bottom: rem(40);

    @media (max-width: $sm-max-width) {
      margin-bottom: rem(15);
    }
  }

  .resultsTitle {
    font-weight: 700;
    margin-bottom: rem(20);
  }

  .resultThumb {
    display: block;
    width: rem(50);
    height: rem(50);
    border-radius: 4px;
    overflow: hidden;
    background-color: $default;
    color: #fff;
    line-height: rem(54);
    text-align: center;
    font-size: rem(18);
    font-weight: bold;
    background-size: cover;
    float: left;
  }

  .resultContent {
    margin-bottom: rem(20);
    min-height: rem(50);
  }

  .result {
    display: block;
    margin-left: rem(70);
    padding-top: rem(8);
  }

  .resultText {
    font-weight: 300;
    font-size: rem(20);
    line-height: 1;

    @media (max-width: $sm-max-width) {
      font-size: rem(16);
    }
  }

  .resultSource {
    color: $text;
  }

  // dark theme
  [data-kit-theme='dark'] {
    .extInput {
      input {
        background-color: $dark-gray-4;
      }
      :global(.anticon) {
        color: $dark-gray-3 !important;
      }
    }

    .livesearch {
      background-color: $dark-gray-6;
    }

    .resultThumb {
      background-color: $dark-gray-4;
    }
  }

</style>
