import '@/core/assets/fonts/ASCircular/style.css'
import '@/core/assets/fonts/font-icomoon/style.css'
import './global.scss' // app & third-party component styles
import '@/core/assets/fonts/font-feathericons/dist/feather.css'
import 'ant-design-vue/lib/style/index.less' // antd core styles
import '@/core/assets/fonts/font-awesome/css/font-awesome.min.css'
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
// import 'ant-design-vue/dist/antd.css';

import Vue from 'vue'
import NProgress from 'vue-nprogress'
import VueSocketIO from 'vue-socket.io';
import socketio from 'socket.io-client';
import BootstrapVue from 'bootstrap-vue'
import VuePageTitle from 'vue-page-title'
import VueSweetalert2 from 'vue-sweetalert2';
import FeatherIcon from '@/components/shared/feather-icon/FeatherIcon.vue'
import ListTable from '@/components/shared/tables/ListTable/ListTable.vue'

import './antd/antd'
import './registerServiceWorker'

import App from './App.vue'
import store from './store'
import router from '@router'
import { i18n } from './localization'

import GeneralMixin from '@/core/mixins/general'

Vue.use(NProgress)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

Vue.use(VuePageTitle, {
  prefix: 'Pent Store | ',
  router,
})

const { VUE_APP_SOCKET_HOST } = process.env

Vue.use(new VueSocketIO({
  connection: socketio(VUE_APP_SOCKET_HOST, {
    autoConnect: false,
    transports: ['websocket'],
  }),
}))

Vue.mixin(GeneralMixin);

Vue.component(ListTable.name, ListTable);
Vue.component(FeatherIcon.name, FeatherIcon);

Vue.config.productionTip = false
const nprogress = new NProgress({ parent: 'body' })

new Vue({
  router,
  store,
  nprogress,
  i18n,
  render: h => h(App),
}).$mount('#app')
